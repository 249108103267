import { Commit } from "vuex";
import { useAdministrator } from "@/services";
import {
  IResponseGetCode,
  IBodyValidateCode,
  IBodySaveNewPassword,
  IResponseValidateCode,
  IResponseSaveNewPassword,
} from "@core/services/interfaces/administrator/IAdministratorService";

export default {
  /**
   * Solicita ao serviço o código de recuperação do usuário
   */
  fetchGetCode(
    { commit }: { commit: Commit },
    email: string
  ): Promise<IResponseGetCode> {
    return new Promise((resolve, reject) => {
      useAdministrator
        .requestGetCode(email)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Solicita ao serviço a validaçao do código de recuperação do usuário
   */
  fetchValidateCode(
    { commit }: { commit: Commit },
    body: IBodyValidateCode
  ): Promise<IResponseValidateCode> {
    return new Promise((resolve, reject) => {
      useAdministrator
        .requetValidateCode(body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Solicita ao serviço que altere a senha do usuário com o código de recuperação
   */
  fetchSaveNewPassword(
    { commit }: { commit: Commit },
    body: IBodySaveNewPassword
  ): Promise<IResponseSaveNewPassword> {
    return new Promise((resolve, reject) => {
      useAdministrator
        .requestSaveNewPassword(body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
