import {
  IBilling,
  IBrandValidation,
  IFirebaseState,
  INotification,
} from "./firebaseState";

export default {
  notifications: (state: IFirebaseState): INotification[] => {
    return state.notifications;
  },
  userProcessing: (state: IFirebaseState): string[] => {
    return state.userProcessing;
  },
  brandValidations: (state: IFirebaseState): IBrandValidation[] => {
    return state.brandValidations;
  },
  billings: (state: IFirebaseState): IBilling[] => {
    return state.billings;
  },
};
