import { IStateConfig } from "@core/services/interfaces/covarege-area/state/IStateService";

const stateConfig: IStateConfig = {
  //Endpoints
  listStateEndpoint: "/api/AreasAbrangencia/Estado/ListarEstados",
  getCitiesByStateIdEndpoint:
    "/api/AreasAbrangencia/Estado/ListarMunicipiosPorEstado",
};

export default stateConfig;
