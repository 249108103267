export interface IMonth {
  Key: number;
  Value: string;
}

export interface IEffectivateBillingState {
  months: IMonth[];
}

let effectivateBillingState: IEffectivateBillingState = {
  months: [],
};

export default effectivateBillingState;
