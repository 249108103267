import { IResponseAuth } from "@core/services/interfaces/auth/IAuthService";
import { Commit } from "vuex/types/index";
import { IAuthInfo } from "./authenticationState";
import { useAuth } from "@/services";
import { AxiosResponse } from "axios";

export default {
  fetchLogin(
    { commit }: { commit: Commit },
    authInfo: IAuthInfo
  ): Promise<AxiosResponse<IResponseAuth>> {
    return new Promise((resolve, reject) => {
      useAuth
        .login(authInfo)
        .then((response) => {
          useAuth.setToken(response.data.token);
          useAuth.setRefreshToken(response.data.refreshToken);
          useAuth.setExpiresAt(response.data.expiresAt);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setDomain({ commit }: { commit: Commit }, domain: string): void {
    useAuth.setDomain(domain);
  },
};
