import useCity from "@core/services/covarege-area/city/useCity";
import axios from "axios";
import defaultConfig from "../../defaultConfig";

const axiosIns = axios.create({
  baseURL: defaultConfig.coverageAreaService,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  },
});

const { city } = useCity(axiosIns, {});
export default city;
