import { Commit } from "vuex";
import { useArea } from "@/services";
import {
  IResponseArea,
  IResponseAddArea,
  IBodyAddArea,
  IBodyEditArea,
  IResponseEditArea,
  IBodyListInfluencedCitiesArea,
  IResponseListInfluencedCitiesArea,
  IResponseDeleteArea,
} from "@core/services/interfaces/covarege-area/area/IAreaService";

export default {
  /**
   * Solicita ao serviço uma area operacional/influencia pelo ID
   */
  fetchGetAreaById(
    { commit }: { commit: Commit },
    areaId: string
  ): Promise<IResponseArea> {
    return new Promise((resolve, reject) => {
      useArea
        .requestGet(areaId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Solicita ao serviço adicionar uma area operacional/influencia
   */
  fetchAddArea(
    { commit }: { commit: Commit },
    area: IBodyAddArea
  ): Promise<IResponseAddArea> {
    return new Promise((resolve, reject) => {
      useArea
        .requestAdd(area)
        .then((response: IResponseAddArea) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Solicita ao serviço a edição de uma area operacional/influencia
   */
  fetchEditArea(
    { commit }: { commit: Commit },
    area: IBodyEditArea
  ): Promise<IResponseEditArea> {
    return new Promise((resolve, reject) => {
      useArea
        .requestEdit(area)
        .then((response: IResponseEditArea) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Solicita ao serviço a remoção de uma area operacional/influencia
   */
  fetchDeleteArea(
    { commit }: { commit: Commit },
    id: number
  ): Promise<IResponseDeleteArea> {
    return new Promise((resolve, reject) => {
      useArea
        .requestDelete(id)
        .then((response: IResponseDeleteArea) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Solicita ao serviço a lista de municípios influênciados ou não de uma area operacional/influência
   */
  fetchListInfluencedCities(
    { commit }: { commit: Commit },
    body: IBodyListInfluencedCitiesArea
  ): Promise<IResponseListInfluencedCitiesArea> {
    return new Promise((resolve, reject) => {
      useArea
        .requestListInfluencedCities(body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchListInfluenceScope({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseListInfluencedCitiesArea> {
    return new Promise((resolve, reject) => {
      useArea
        .requestListInfluenceScopes()
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
