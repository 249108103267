import { Commit } from 'vuex/types/index';
import { TResponseContacts } from "./contactsState";

export default {
  fetchContacts( { commit }: { commit: Commit } ) : Promise<TResponseContacts> {     
    return new Promise( ( resolve: Function, reject: Function ) => {
      const data: TResponseContacts = {
        data: [
          {
            id: 1,
            name: 'Weslan',
            position: 'Cargo I',
            dpo: false,
            email: 'weslan.alves@avonale.com.br',
            telephone: '(79) 9 9673-1945',
          },
          {
            id: 2,
            name: 'Raphael',
            position: 'Cargo II',
            dpo: true,
            email: 'raphael.sena@avonale.com.br',
            telephone: '(79) 9 9999-9999',
          },
          {
            id: 3,
            name: 'Bárbará',
            position: 'Cargo III',
            dpo: false,
            email: 'barbara@avonale.com.br',
            telephone: '(79) 9 9999-9999',
          }
        ],
        meta: {
          pagination: {
            total: 3
          }
        }
      };

      resolve( data );
    } )
  },
};