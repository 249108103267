import { Commit } from 'vuex/types/index';
import { TResponseCounties } from "./countiesState";

export default {
  fetchCounties( { commit }: { commit: Commit } ) {
    const dataFake: TResponseCounties = {
      data: [
        {
          Id      : 1,
          name    : "ABADIA DE GOIAS",
          serpro  : "5200050",
          ibge    : "5200050",
          state   : 'Goiás',
          area    : "A1",
          region  : "R1",
          capital : true,
        },
        {
          Id      : 2,
          name    : "Aracaju",
          serpro  : "5200050",
          ibge    : "5200050",
          state   : 'Goiás',
          area    : "A2",
          region  : "R2",
          capital : true,
        },
        {
          Id      : 3,
          name    : "Rio de Janeiro",
          serpro  : "5200050",
          ibge    : "5200050",
          state   : 'Goiás',
          area    : "A3",
          region  : "R3",
          capital : true,
        },
        {
          Id      : 4,
          name    : "Nossa Senhora do Socorro",
          serpro  : "5200050",
          ibge    : "5200050",
          state   : 'Goiás',
          area    : "A2",
          region  : "R2",
          capital : false,
        },
        {
          Id      : 5,
          name    : "Teste",
          serpro  : "5200050",
          ibge    : "5200050",
          state   : 'Goiás',
          area    : "A2",
          region  : "R2",
          capital : false,
        },
      ],
      draw            : 1,
      recordsTotal    : 3,
      recordsFiltered : 3,
      lastPage        : 1
    };

    return new Promise( ( resolve, reject ) => {
      resolve( dataFake );
    } )
  },
};