export interface IGroupsEconomicState {}

let groupsEconomicState: IGroupsEconomicState = {};

export default groupsEconomicState;

export interface IGroupEconomic {
  id?: number;
  code: number;
  name: string;
  status: boolean;
  userBlockingId?: number | null;
  descriptionBlocking?: string | null;
}
