import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/pt_BR.json";

//Custom
import customRules from "./rules/rules";
import pt_BR, { messages as pt_BR_messages } from "./locales/pt_BR.js";

localize("pt_BR", pt_BR);

customRules.forEach( rule => {
  extend( rule.name, {
    validate: rule.rule,
    message: pt_BR_messages[ rule.name ],
  } );
} );

Object.keys( rules ).forEach( rule => {
  extend( rule, {
    ...rules[ rule ], // copies rule configuration
    message: messages[ rule ], // assign message
  } );
} );

export { pt_BR, customRules };
