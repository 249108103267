import useGeralImport from "@core/services/geralEmplacamento/geralImport/useGeralImport";
import axios from "axios";
import defaultConfig from "../../defaultConfig";

const axiosIns = axios.create({
  baseURL: defaultConfig.generalEmplacamentoService,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  },
});

const { geralImport } = useGeralImport(axiosIns, {});
export default geralImport;
