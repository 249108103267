import actions from "./magazinesActions";
import getters from "./magazinesGetters";
import state from "./magazinesState";
import mutations from "./magazinesMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};