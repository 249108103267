import mutations from "./effectivateBillingMutations";
import getters from "./effectivateBillingGetters";
import state from "./effectivateBillingState";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
