import AuthService from "@core/services/auth/authService";
import defaultConfig from "./dealershipsDefaultConfig";
import { AxiosInstance, AxiosResponse } from "axios";
import {
  IDearlershipsService,
  TDealershipsConfig,
  IResponseGetDealership,
  IResponseListDealerships,
  IResponseGetFiltersDealerships,
  IResponseCreateDealership,
  IUpdateDealershipBody,
  ICreateDealershipBody,
  IFieldControl,
  IResponseValidCnpj,
} from "@core/services/interfaces/business/dealerships/IDealershipsService";
import { IBodyDealerships } from "@core/services/interfaces/business/dealerships/IBodySearch";

export default class DearlershipsService
  extends AuthService
  implements IDearlershipsService
{
  serviceDealershipsConfig: TDealershipsConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceDealershipsConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Retorna uma lista concessionárias por Id
   */
  requestGet(dealershipsId: string): Promise<IResponseGetDealership> {
    return this.axiosIns.get(
      this.serviceDealershipsConfig.getDealershipByIdEndpoint +
        `?id=${dealershipsId}`
    );
  }

  requestUpdate(
    body: IUpdateDealershipBody
  ): Promise<IResponseCreateDealership> {
    return this.axiosIns.put(
      this.serviceDealershipsConfig.updateDealershipEndpoint,
      body
    );
  }

  requestCreate(
    body: ICreateDealershipBody
  ): Promise<IResponseCreateDealership> {
    return this.axiosIns.post(
      this.serviceDealershipsConfig.createDealershipEndpoint,
      body
    );
  }

  /**
   * Retorna uma lista concessionárias por Id
   */
  requestGetFilters(): Promise<IResponseGetFiltersDealerships> {
    return this.axiosIns.get(
      this.serviceDealershipsConfig.getFiltersDealershipsIdEndpoint
    );
  }

  /**
   * Retorna uma lista de concessionárias
   */
  requestList(args: IBodyDealerships): Promise<IResponseListDealerships> {
    return this.axiosIns.post(
      this.serviceDealershipsConfig.getListDealershipsEndpoint +
        `?paginar=${args.paginate}`,
      args
    );
  }

  /**
   * True - válido
   * False - inválido
   */
  requestValidCnpj(
    cnpj: string,
    checkHimself = false
  ): Promise<boolean | null> {
    return this.axiosIns
      .post(
        this.serviceDealershipsConfig.getListDealershipsEndpoint +
          "?paginar=false",
        {
          draw: 1,
          userdata: {
            cnpj,
          },
        }
      )
      .then((response: AxiosResponse<IResponseValidCnpj>): boolean => {
        if (response.data.Data.data.length == 0) {
          return true;
        } else if (checkHimself) {
            return response.data.Data.data[0].CNPJ == cnpj;
        } else {
          return false;
        }
      })
      .catch(() => {
        return null;
      });
  }

  getFieldControl(): IFieldControl {
    const fieldControl = require(`@core/services/business/dealerships/fieldControl/field.${this.getDomain()}`);
    return fieldControl.default;
  }
}
