import { TValidationConfig } from "../interfaces/brand-validation/IValidationService";

const brandDefaultConfig: TValidationConfig = {
  // Endpoints
  getValidationTypesEndpoint: "/api/ValidacaoMarca/ObterTiposValidacaoMarca",
  deleteValidationEndpoint: "/api/ValidacaoMarca",
  initiateValidationEndpoint: "/api/ValidacaoMarca/IniciarValidacaoMarcaManual",
  listValidationEndpoint: "/api/ValidacaoMarca/ObterValidacoesMarca",
};

export default brandDefaultConfig;
