export interface IGroupsState {}

let groupsState: IGroupsState = {};

export default groupsState;

export type TQueryParams = {
  q         : string,
  perPage   : number,
  page      : number,
  sortBy    : string,
  sortDesc  : string,
  status    : boolean,
  fabricante: number,
  code      : string
  modelName : string
}