import AuthService from "@core/services/auth/authService";
import defaultConfig from "./groupEconomicDefaultConfig";
import { AxiosInstance } from "axios";
import {
  IGroupEconomic,
  IGroupEconomicService,
  TGroupEconomicConfig,
  IResponseListGroupEconomic,
  IResponseGetGroupEconomic,
  IGroupEconomicCreateResponse,
  IGroupEconomicUpdateResponse,
  IGroupEconomicDeleteResponse,
  IBodyListGroupEconomic,
} from "@core/services/interfaces/business/group-economic/IGroupEconomicService";

export default class GroupEconomicService
  extends AuthService
  implements IGroupEconomicService
{
  serviceGroupEconomicConfig: TGroupEconomicConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceGroupEconomicConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Retorna um grupo econômico pelo ID
   */
  requestGetById(groupEconomicId: number): Promise<IResponseGetGroupEconomic> {
    return this.axiosIns.get(
      this.serviceGroupEconomicConfig.getGroupEconomicByIdEndpoint +
        "?id=" +
        groupEconomicId
    );
  }

  /**
   * Retorna lista de grupo econômico
   */
  requestGet(): Promise<IResponseListGroupEconomic> {
    return this.axiosIns.get(
      this.serviceGroupEconomicConfig.getGroupEconomicEndpoint
    );
  }

  /**
   * Retorna uma lista grupos econômicos
   */
  requestList(
    args: IBodyListGroupEconomic
  ): Promise<IResponseListGroupEconomic> {
    let strArgs = `?${args.paginate ? "paginar=true" : "paginar=false"}`;

    return this.axiosIns.get(
      this.serviceGroupEconomicConfig.listGroupEconomicEndpoint + strArgs,
      {
        params: args,
      }
    );
  }

  /**
   * Cria um grupo econômico
   */
  requestCreate(
    groupEconomic: IGroupEconomic
  ): Promise<IGroupEconomicCreateResponse> {
    return this.axiosIns.post(
      this.serviceGroupEconomicConfig.createGroupEconomicEndpoint,
      groupEconomic
    );
  }

  /**
   * Atualiza um grupo econômico
   */
  requestUpdate(
    groupEconomic: IGroupEconomic
  ): Promise<IGroupEconomicUpdateResponse> {
    return this.axiosIns.put(
      this.serviceGroupEconomicConfig.updateGroupEconomicEndpoint,
      groupEconomic
    );
  }

  /**
   * Apaga um grupo econômico
   */
  requestDelete(
    groupEconomicId: number
  ): Promise<IGroupEconomicDeleteResponse> {
    return this.axiosIns.delete(
      this.serviceGroupEconomicConfig.deleteGroupEconomicEndpoint +
        "?id=" +
        groupEconomicId
    );
  }

  verifyEconomicGroupPermission(): boolean {
    let params = JSON.parse(localStorage.getItem("params") || "");

    if (params.EMP_ASSOCIACAO === "Abracaf") {
      return true;
    } else {
      return false;
    }
  }
}
