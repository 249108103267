import AuthService from "@core/services/auth/authService";
import defaultConfig from "./regionDefaultConfig";
import { AxiosInstance, AxiosResponse } from "axios";
import {
  IRegion,
  IRegionService,
  TRegionConfig,
  IResponseCityRegion,
  IResponseRegionScope,
  IResponseGetRegion,
  IResponseListRegion,
  IResponseCreateRegion,
  IResponseUpdateRegion,
  IResponseGetRegionActive,
} from "@core/services/interfaces/covarege-area/region/IRegionService";
import { IBodyListRegion } from "@core/services/interfaces/covarege-area/region/IBodySearch";

export default class RegionService
  extends AuthService
  implements IRegionService
{
  serviceRegionConfig: TRegionConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceRegionConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Retorna uma lista regiões ativas
   */
  requestGetActives(): Promise<IResponseGetRegionActive> {
    return this.axiosIns.get(this.serviceRegionConfig.getActiveRegionsEndpoint);
  }

  /**
   * Retorna uma região pelo ID
   */
  requestGet(regionId: number): Promise<IResponseGetRegion> {
    return this.axiosIns.get(
      this.serviceRegionConfig.getRegionEndpoint + "/?id=" + regionId
    );
  }

  /**
   * Retorna os municípios de uma região
   */
  requestGetCityRegion(): Promise<IResponseCityRegion> {
    return this.axiosIns.get(this.serviceRegionConfig.getCityRegionEndpoint);
  }

  /**
   * Retorna as regiões de escopo. Essa rota é utilizada para filtragem do que
   * o usuário pode ou não visualizar.
   */
  requestGetRegionScope(): Promise<IResponseRegionScope> {
    return this.axiosIns.get(this.serviceRegionConfig.getRegionScopeEndpoint);
  }

  /**
   * Retorna uma lista de regiões
   */
  requestList(
    args: IBodyListRegion
  ): Promise<AxiosResponse<IResponseListRegion>> {
    const paginate = `?paginar=${args.paginate ? args.paginate : "false"}`;

    return this.axiosIns.post(
      this.serviceRegionConfig.listRegionEndpoint + paginate,
      args
    );
  }

  /**
   * Cria um região
   */
  requestCreate(region: IRegion): Promise<IResponseCreateRegion> {
    return this.axiosIns.post(
      this.serviceRegionConfig.createRegionEndpoint,
      region
    );
  }

  /**
   * Atualiza uma região
   */
  requestUpdate(region: IRegion): Promise<IResponseUpdateRegion> {
    return this.axiosIns.put(
      this.serviceRegionConfig.updateRegionEndpoint,
      region
    );
  }

  /**
   * True - válido
   * False - inválido
   */
  requestValidCode(
    code: number,
    checkHimself = false
  ): Promise<boolean | null> {
    return this.axiosIns
      .post(this.serviceRegionConfig.listRegionEndpoint + "?paginar=false", {
        draw: 1,
        userdata: {
          codigo: code,
        },
      })
      .then((response: AxiosResponse<IResponseListRegion>): boolean => {
        if (response.data.Data.data.length == 0) {
          return true;
        } else if (checkHimself) {
          if (response.data.Data.data.length == 1) {
            const index = response.data.Data.data.findIndex(
              (it) => it.Codigo == code
            );
            return index == -1;
          } else {
            return false;
          }
        } else {
          return false;
        }
      })
      .catch(() => {
        return null;
      });
  }
}
