import AuthService from "@core/services/auth/authService";
import defaultConfig from "./personDefaultConfig";
import { AxiosInstance } from "axios";
import {
  IPersonService,
  IPersonConfig,
  IResponseValidCpfCnpj,
} from "@core/services/interfaces/geral/person/IPersonService";

export default class PersonService
  extends AuthService
  implements IPersonService
{
  servicePersonConfig: IPersonConfig;
  constructor(axiosIns: AxiosInstance, userOverrideConfig: Object) {
    super(axiosIns, userOverrideConfig);
    this.servicePersonConfig = { ...defaultConfig, ...userOverrideConfig };
  }

  requestValidCpfCnpj(cpfCnpj: string): Promise<IResponseValidCpfCnpj> {
    return this.axiosIns.get(
      this.servicePersonConfig.validCpfCnpjPersonEndpoint + `/${cpfCnpj}`
    );
  }
}
