import actions from "./usersActions";
import getters from "./usersGetters";
import state from "./usersState";
import mutations from "./usersMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};