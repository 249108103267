import { IOldServiceConfig } from "@core/services/interfaces/oldService/IOldService";

const oldServiceConfig: IOldServiceConfig = {
  // Endpoints
  getTotalMarketShareCurrentMonthEndpoint:
    "/api/Emplacamento/DashboardKPI/ParticipacaoMercadoTotalMes",
  getTotalMarketShareYearToDateEndpoint:
    "/api/Emplacamento/DashboardKPI/ParticipacaoMercadoTotalAno",
  getLargestEconomicGroupsYearEndpoint:
    "/api/Emplacamento/DashboardKPI/ParticipacaoMarca",
  getGrowthByBrandEndpoint: "api/Emplacamento/DashboardKPI/Variacao",
  getDirectSaleVsRetailSaleBrandEndpoint:
    "/api/Emplacamento/DashboardKPI/VendaDiretaXVarejoMarca",
  getDirectSaleVsRetailSaleYearEndpoint:
    "/api/Emplacamento/DashboardKPI/VendaDiretaXVarejoAno",
  getBrandEvolutionEndpoint: "/api/Emplacamento/DashboardKPI/EvolucaoMarca",
  getDailyBrandEvolutionEndpoint:
    "/api/Emplacamento/DashboardKPI/EvolucaoMarcaDiario",
  updateDashboardEndpoint: "/api/Emplacamento/DashboardKPI/AtualizarDashboard",
  getImportEmplacamentoEndpoint: "/api/Emplacamento/ImportacaoEmplacamento",
  getBillingEmplacamentoEndpoint:
    "/api/Emplacamento/ImportacaoFaturamento/ConsultarImportacoesFaturamento",
  uploadFileEndpoint: "/api/FileUpload",
  deleteFileEndpoint: "/api/FileUpload",
  getFileEndpoint: "/api/FileUpload",
  createImportEmplacamentoEndpoint:
    "/api/Emplacamento/ImportacaoEmplacamento/CriarImportacao",
  createImportBillingEndpoint:
    "/api/Emplacamento/ImportacaoFaturamento/ImportarFaturamentos",
  getEffectivateBillingEndpoint: "/api/Emplacamento/EfetivarFaturamentos",
  getBusinessWithBillingEndpoint:
    "/api/Emplacamento/EfetivarFaturamentos/ObterEmpresasComFaturamentos",
  generatePendingToSendCollectionLetterEndpoint:
    "/api/Emplacamento/EfetivarFaturamentos/GerarPendenciaEnviarCartaCobranca/?forcarReenvio=false",
  updateBillingParametersEndpoint:
    "/api/Emplacamento/EfetivarFaturamentos/AtualizarParametrosDeFaturamento",
};

export default oldServiceConfig;
