function valorMinimo(valor, valorMinimo) {
  let valorFormatado;
  if (valor) {
    valorFormatado = Number(valor.slice(3).replace(/\D/g, ""));
  }
  if (valorFormatado >= Number(valorMinimo[0])) {
    return true;
  }

  return false;
}

export default valorMinimo;