import { TModelConfig } from "../../interfaces/vehicle/models/IModelService";

const modelDefaultConfig: TModelConfig = {
  // Endpoints
  updateModelEndpoint: "/api/GerenciarVeiculos/Modelo",
  createModelEndpoint: "/api/GerenciarVeiculos/Modelo",
  listModelsEndpoint: "/api/GerenciarVeiculos/Modelo/ObterModelos",
  getModelByIdEndpoint: "/api/GerenciarVeiculos/Modelo/ObterModelo",
  getFabricantesEndpoint: "/api/GerenciarVeiculos/Fabricante/ListarFabricantes",
};

export default modelDefaultConfig;
