import { useBrand } from "@/services";
import { Commit } from "vuex/types/index";
import {
  IResponseGetBrandById,
  IResponseListBrands,
  IBrandCreateResponse,
  IBrandUpdateResponse,
  IBrandDeleteResponse,
  IBrandWrite,
} from "@core/services/interfaces/vehicle/brands/IBrandService";

export default {
  fetchGetBrandById(
    { commit }: { commit: any },
    brandId: number
  ): Promise<IResponseGetBrandById> {
    return new Promise((resolve, reject) => {
      useBrand
        .requestGetById(brandId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchListBrands({
    commit,
  }: {
    commit: any;
  }): Promise<IResponseListBrands> {
    return new Promise((resolve, reject) => {
      useBrand
        .requestList({ draw: 0 })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async fetchCreateBrand(
    { commit }: { commit: any },
    brand: IBrandWrite
  ): Promise<IBrandCreateResponse> {
    return new Promise((resolve, reject) => {
      useBrand
        .requestCreate(brand)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchUpdateBrand(
    { commit }: { commit: Commit },
    brand: IBrandWrite
  ): Promise<IBrandUpdateResponse> {
    return new Promise((resolve, reject) => {
      useBrand
        .requestUpdate(brand)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchDeleteBrand(
    { commit }: { commit: Commit },
    brandId: number
  ): Promise<IBrandDeleteResponse> {
    return new Promise((resolve, reject) => {
      useBrand
        .requestDelete(brandId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
