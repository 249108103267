enum ICON {
  ADD = "add",
  DELETE = "delete",
  PASSWORD = "password",
  PLUS = "plus",
  CAR = "car",
  CLOSE = "close",
  ACCOUNT_OULTINE = "account-outline",
  CALENDAR_TODAY = "calendar-today",
  CALENDAR_BLANK = "calendar-blank",
  CALENDAR_RANGE = "calendar-range",
  CART_OUTLINE = "cart-outline",
  CHEVRON_DOWN = "chevron-down",
  CHEVRON_UP = "chevron-up",
  CHEVRON_RIGHT = "chevron-right",
  CHEVRON_LEFT = "chevron-left",
  DOTS_VERTICAL = "dots-vertical",
  FLAG_VARIANT_OUTLINE = "flag-variant-outline",
  GRID_OFF = "grid-off",
  SPEEDOMETER = "speedometer",
  EDIT = "edit",
  STORE = "store",
  MAP_MARKER_OUTLINE = "map-marker-outline",
  MAP_MARKER_OFF = "map-marker-off",
  MAP_MARKER = "map-marker",
  MAGNIFY = "magnify",
}

export default ICON;
