import { AxiosInstance } from "axios";
import BrandService from "./brandsService";

export default function useBrand(
  axiosIns: AxiosInstance,
  brandOverrideConfig: Object
) {
  const brand = new BrandService(axiosIns, brandOverrideConfig);

  return {
    brand,
  };
}
