enum BUTTON {
  ADICIONAR = "Adicionar",
  BUSCAR = "Buscar",
  CONFIRMAR = "Confirmar",
  CONTINUAR = "Continuar",
  CANCELAR = "Cancelar",
  DESCARTAR = "Descartar",
  DESFAZER = "Desfazer",
  EDITAR = "Editar",
  FILTRAR = "Filtrar",
  FECHAR = "Fechar",
  PROXIMO = "Próximo",
  LIMPAR = "Limpar",
  RESETAR_FILTROS = "Resetar filtros",
  SOLICITAR = "Solicitar",
  SALVAR = "Salvar",
  SAIR = "Sair",
  SIM = "Sim",
  VOLTAR = "Voltar",
  ENVIAR_CARTA_COBRANCA = "Enviar Carta Cobrança",
}

export default BUTTON;
