import actions from "./groupsEconomicActions";
import getters from "./groupsEconomicGetters";
import state from "./groupsEconomicState";
import mutations from "./groupsEconomicMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};