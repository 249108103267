import { AxiosInstance } from "axios";
import SegmentService from "./segmentService";

export default function useSegment(
  axiosIns: AxiosInstance,
  segmentOverrideConfig: Object
) {
  const segment = new SegmentService(axiosIns, segmentOverrideConfig);

  return {
    segment,
  };
}
