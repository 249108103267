import AuthService from "@core/services/auth/authService";
import defaultConfig from "./segmentDefaultConfig";
import { AxiosInstance } from "axios";
import {
  IResponseGetSegment,
  IResponseGetSegmentTypes,
  IResponseListSegment,
  ISegment,
  ISegmentCreateResponse,
  ISegmentDeleteResponse,
  ISegmentService,
  ISegmentUpdateResponse,
  TSegmentConfig,
  ISegmentUpdate,
  ISegmentCreate,
} from "@core/services/interfaces/vehicle/segment/ISegmentService";
import { IBodyListSegment } from "@core/services/interfaces/vehicle/segment/IBodySearch";

export default class SegmentService
  extends AuthService
  implements ISegmentService
{
  serviceSegmentConfig: TSegmentConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceSegmentConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Retorna um segmento pelo ID
   */
  requestGet(id: number): Promise<IResponseGetSegment> {
    return this.axiosIns.get(
      this.serviceSegmentConfig.getSegmentEndpoint + "?segmentoId=" + id
    );
  }

  requestGetSegmentTypes(): Promise<IResponseGetSegmentTypes> {
    return this.axiosIns.get(this.serviceSegmentConfig.getSegmentTypesEndpoint);
  }

  /**
   * Retorna uma lista de segmentos
   */
  async requestList(args?: IBodyListSegment): Promise<IResponseListSegment> {
    let strArgs = "";

    if (args && Object.entries(args).length > 0) {
      strArgs = "?";

      Object.entries(args).forEach(
        ([key, value]: [string, any], index: number): void => {
          strArgs += `${key}=${value}`;
        }
      );
    }

    return await this.axiosIns.get(
      this.serviceSegmentConfig.listSegmentEndpoint + strArgs
    );
  }

  /**
   * Cria um segmento
   */
  requestCreate(segment: ISegmentCreate): Promise<ISegmentCreateResponse> {
    return this.axiosIns.post(
      this.serviceSegmentConfig.createSegmentEndpoint,
      segment
    );
  }

  /**
   * Atualiza um segmento
   */
  requestUpdate(segment: ISegmentUpdate): Promise<ISegmentUpdateResponse> {
    return this.axiosIns.put(
      this.serviceSegmentConfig.updateSegmentEndpoint,
      segment
    );
  }

  /**
   * Apaga um segmento
   */
  requestDelete(id: number): Promise<ISegmentDeleteResponse> {
    return this.axiosIns.delete(
      this.serviceSegmentConfig.deleteSegmentEndpoint + "?id=" + id
    );
  }
}
