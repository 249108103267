import { ICounties, ICountiesState, IStateStore, IAreaStore } from "./cityState";

export default {
  /**
   * Recebe uma lista de municípios e set no state Counties
   */
  SET_COUNTY(
    state: ICountiesState,
    counties: ICounties[]
  ) : void {
    state.counties = counties;
  },
  SET_STATE(
    state: ICountiesState,
    states: IStateStore[]
  ) : void {
    state.states = states;
  },
  SET_AREAS(
    state: ICountiesState,
    areas: IAreaStore[]
  ) : void {
    state.areas = areas;
  }
};