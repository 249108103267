import AuthService from "@core/services/auth/authService";
import defaultConfig from "./archiveDefaultConfig";
import { AxiosInstance } from "axios";
import {
  IArchiveService,
  IArchiveConfig,
  IBodyGetArchives,
  IResponseGetArchives,
  IResponseDeleteArchive,
} from "@core/services/interfaces/geral/archive/IArchiveService";

export default class ArchiveService
  extends AuthService
  implements IArchiveService
{
  serviceArchiveConfig: IArchiveConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceArchiveConfig = { ...defaultConfig, ...overrideConfig };
  }

  requestGetArchives(body?: IBodyGetArchives): Promise<IResponseGetArchives> {
    return this.axiosIns.get(
      this.serviceArchiveConfig.getArchiveValueEndpoint,
      {
        params: body,
      }
    );
  }

  requestDeleteArchive(id: string): Promise<IResponseDeleteArchive> {
    return this.axiosIns.delete(
      this.serviceArchiveConfig.deleteArchiveValueEndpoint + `?id=${id}`
    );
  }
}
