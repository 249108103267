import events from "@/resources/systems-modules-events/events.json";

export default [
  // *===============================================---*
  // *-------------------Relatórios---------------------*
  // *===============================================---*
  {
    path: "/reports/daily-report",
    name: "reports-daily-report",
    meta: {
      pageTitle: "Boletim diário",
      action: "read",
      resource: "Auth",
      embedUrl: "/Emplacamento/BoletimDiario",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/by-brands",
    name: "reports-by-brands",
    meta: {
      pageTitle: "Por Marcas",
      action: "manage",
      resource: events.Emp_Por_marcas.id,
      embedUrl: "/Emplacamento/Marca",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/from-your-dealership",
    name: "reports-from-your-dealership",
    meta: {
      pageTitle: "Da Sua Concessionária",
      action: "manage",
      resource: events.Emp_Por_marcas.id,
      embedUrl: "/Emplacamento/Concessionaria",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/by-city-dealership",
    name: "reports-by-city-dealership",
    meta: {
      pageTitle: "Por Cidade - Concessionária",
      action: "manage",
      resource: events.Emp_Por_cidade_concessionaria.id,
      embedUrl: "/Emplacamento/CidadeConcessionaria",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/by-brand-last-12-months",
    name: "reports-by-brand-last-12-months",
    meta: {
      pageTitle: "Por Marcas Últimos 12 Meses",
      action: "manage",
      resource: events.Emp_Por_marcas_ultimos_12_meses.id,
      embedUrl: "/Emplacamento/MarcaMes",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/by-brands-year-by-year",
    name: "reports-by-brands-year-by-year",
    meta: {
      pageTitle: "Por Marcas Ano a Ano",
      action: "manage",
      resource: events.Emp_Por_marcas_ano_a_ano.id,
      embedUrl: "/Emplacamento/MarcaAno",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/by-area-of-influence",
    name: "reports-by-area-of-influence",
    meta: {
      pageTitle: "Por Área de Influência",
      action: "manage",
      resource: events.Emp_Por_area_de_influencia.id,
      embedUrl: "/Emplacamento/AreaInfluencia",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/by-regions",
    name: "reports-by-regions",
    meta: {
      pageTitle: "Por Regiões",
      action: "manage",
      resource: events.Emp_Por_regioes.id,
      embedUrl: "/Emplacamento/Regiao",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/by-model-year-by-year",
    name: "reports-by-model-year-by-year",
    meta: {
      pageTitle: "Por Modelo Ano a Ano",
      action: "manage",
      resource: events.Emp_Por_modelo_ano_a_ano.id,
      embedUrl: "/Emplacamento/ModeloAno",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/by-model-month-by-month",
    name: "reports-by-model-month-by-month",
    meta: {
      pageTitle: "Por Modelo Mês a Mês",
      action: "manage",
      resource: events.Emp_Por_modelo_mes_a_mes.id,
      embedUrl: "/Emplacamento/ModeloMes",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/model-ranking",
    name: "reports-model-ranking",
    meta: {
      pageTitle: "Ranking de Modelos",
      action: "manage",
      resource: events.Emp_Ranking_de_modelos.id,
      embedUrl: "/Emplacamento/RankingModelo",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/city-ranking",
    name: "reports-city-ranking",
    meta: {
      pageTitle: "Ranking de Cidades",
      action: "manage",
      resource: events.Emp_Ranking_cidades.id,
      embedUrl: "/Emplacamento/RankingCidade",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/accumulated-segment",
    name: "reports-accumulated-segment",
    meta: {
      pageTitle: "Segmento Acumulado",
      action: "manage",
      resource: events.Emp_Segmento_acumulado.id,
      embedUrl: "/Emplacamento/SegmentoAcumulado",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/segment-by-month",
    name: "reports-segment-by-month",
    meta: {
      pageTitle: "Segmento Mês a Mês",
      action: "manage",
      resource: events.Emp_Segmento_mes_a_mes.id,
      embedUrl: "/Emplacamento/SegmentoMes",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/ranking-of-dealerships",
    name: "reports-ranking-of-dealerships",
    meta: {
      pageTitle: "Ranking de Concessionárias",
      action: "manage",
      resource: events.Emp_Ranking_de_concessionarias.id,
      embedUrl: "/Emplacamento/RankingConcessionaria",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/vehicle-information",
    name: "reports-vehicle-information",
    meta: {
      pageTitle: "Informações de Veículos",
      action: "manage",
      resource: events.Emp_Informacoes_de_veiculos.id,
      embedUrl: "/Emplacamento/InformacaoVeiculo",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/access-history",
    name: "reports-access-history",
    meta: {
      pageTitle: "Histórico de Acesso",
      action: "manage",
      resource: events.Emp_Historico_dos_acessos.id,
      embedUrl: "/Emplacamento/HistoricoAcesso",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/access-reports-by-users",
    name: "reports-access-reports-by-users",
    meta: {
      pageTitle: "Relatórios de Acesso - Por Usuário",
      action: "manage",
      resource: events.Emp_Relatorios_de_acesso_por_usuario.id,
      embedUrl: "/Emplacamento/AcessoUsuario",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/access-report-by-group-company",
    name: "reports-access-report-by-group-company",
    meta: {
      pageTitle: "Relatórios de Acesso - Por Grupo/Empresa",
      action: "manage",
      resource: events.Emp_Relatorios_de_acesso_por_grupo_empresa.id,
      embedUrl: "/Emplacamento/AcessoGrupo",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/access-report-by-report",
    name: "reports-access-report-by-report",
    meta: {
      pageTitle: "Relatório de Acessos Por Relatório",
      action: "manage",
      resource: events.Emp_Relatorios_de_acesso_por_relatorio.id,
      embedUrl: "/Emplacamento/AcessoRelatorio",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/invasion",
    name: "reports-invasion",
    meta: {
      pageTitle: "Invasão",
      action: "manage",
      resource: events.Emp_Invasao.id,
      embedUrl: "/Emplacamento/Invasao",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/annual-capital",
    name: "reports-annual-capital",
    meta: {
      pageTitle: "Capitais Anual",
      action: "manage",
      resource: events.Emp_Capitais_anual.id,
      embedUrl: "/Emplacamento/CapitalAno",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/monthly-capital",
    name: "reports-monthly-capital",
    meta: {
      pageTitle: "Capitais Mensal",
      action: "manage",
      resource: events.Emp_Capitais_mensal.id,
      embedUrl: "/Emplacamento/CapitalMes",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/capital-brands",
    name: "reports-capital-brands",
    meta: {
      pageTitle: "Capitais e Marcas",
      action: "manage",
      resource: events.Emp_Capitais_e_marcas.id,
      embedUrl: "/Emplacamento/CapitalMarca",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/annual-ranking-by-brands",
    name: "reports-annual-ranking-by-brands",
    meta: {
      pageTitle: "Ranking Anual Por Marcas",
      action: "manage",
      resource: events.Emp_Ranking_Anual_por_marcas.id,
      embedUrl: "/Emplacamento/RankingMarca",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/dynamic-table",
    name: "reports-dynamic-table",
    meta: {
      pageTitle: "Tabela Dinâmica - Emplacamentos",
      action: "manage",
      resource: events.Emp_Tabela_Dinamica.id,
      embedUrl: "/Emplacamento/EmplacamentoPivotGrid",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/regions-areas-cities",
    name: "reports-regions-areas-cities",
    meta: {
      pageTitle: "Região, Área e Município por Concessionária",
      action: "manage",
      resource: events.Emp_Regioes_Areas_e_Municipios.id,
      embedUrl: "/Emplacamento/RegiaoAreaMunicipio",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/billing-report",
    name: "reports-billing-report",
    meta: {
      pageTitle: "Relatório de Faturamento",
      action: "manage",
      resource: events.Emp_Relatorio_de_Faturamentos.id,
      embedUrl: "/Emplacamento/RelatorioDeFaturamentos",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/invoicing-dvr",
    name: "reports-invoicing-dvr",
    meta: {
      pageTitle: "Faturamento DVR",
      action: "manage",
      resource: events.Emp_Faturamento_DVR.id,
      embedUrl: "/Emplacamento/FaturamentoDVR",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/invoicing-dve",
    name: "reports-invoicing-dve",
    meta: {
      pageTitle: "Faturamento DVE",
      action: "manage",
      resource: events.Emp_Faturamento_DVE.id,
      embedUrl: "/Emplacamento/FaturamentoDVE",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/invoicing-consolidated",
    name: "reports-invoicing-consolidated",
    meta: {
      pageTitle: "Faturamento Consolidado",
      action: "manage",
      resource: events.Emp_Faturamento_Consolidado.id,
      embedUrl: "/Emplacamento/FaturamentoConsolidado",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/reports/dual-plate",
    name: "reports-dual-plate",
    meta: {
      pageTitle: "Duplo Emplacamento",
      action: "manage",
      resource: events.Emp_Duplo_emplacamento.id,
      embedUrl: "/Emplacamento/DuploEmplacamento",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/charts/sales-type-chart",
    name: "reports-sales-type-chart",
    meta: {
      pageTitle: "Gráfico Tipo de Vendas",
      action: "manage",
      resource: events.Emp_Grafico_tipo_vendas.id,
      embedUrl: "/Emplacamento/TipoVenda",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/charts/last-13-months-market-share-chart",
    name: "reports-last-13-months-market-share-chart",
    meta: {
      pageTitle: "Gráfico de Market Share dos Últimos 13 Meses",
      action: "manage",
      resource: events.Emp_Grafico_market_share_ultimos_13_meses.id,
      embedUrl: "/Emplacamento/MarketShareMes",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/charts/market-share-graph",
    name: "reports-market-share-graph",
    meta: {
      pageTitle: "Gráfico de Market Share",
      action: "manage",
      resource: events.Emp_Grafico_market_share.id,
      embedUrl: "/Emplacamento/MarketShare",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/charts/regional-market-share-graph",
    name: "reports-regional-market-share-graph",
    meta: {
      pageTitle: "Gráfico de Market Share Regional",
      action: "manage",
      resource: events.Emp_Grafico_market_share_regional.id,
      embedUrl: "/Emplacamento/MarketShareRegional",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/charts/automaker-comparison-graph",
    name: "reports-automaker-comparison-graph",
    meta: {
      pageTitle: "Gráfico do Comparativo de Montadoras",
      action: "manage",
      resource: events.Emp_Grafico_comparativo_de_montadoras.id,
      embedUrl: "/Emplacamento/ComparativoMontadoras",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
  {
    path: "/charts/daily-average-market-share-graph",
    name: "reports-daily-average-market-share-graph",
    meta: {
      pageTitle: "Gráfico de Market Share Média Diária",
      action: "manage",
      resource: events.Emp_Grafico_comparativo_de_montadoras.id,
      embedUrl: "/Emplacamento/MarketShareMediaDiaria",
    },
    component: () =>
      import("@/views/pages/embed-emplacamento/EmbedEmplacamento.vue"),
  },
];
