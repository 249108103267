/*
    Para adicionar uma nova validação, é necessário criar a função que retornara true,
    ou false, e adicionalá no extend, juntamente com o nome. E adicionar um novo objeto
    com o nome da regra.Por conversão, está sendo adicionado com o mesmo nome
*/

import placa from "./placa/placa";
import valorMinimo from "./valorMinimo/valorMinimo";
import chassi from "@/libs/vee-validate/rules/chassi/chassi";
import renavam from "@/libs/vee-validate/rules/renavam/renavam.js";
import valorMinimoDecimal from "./valorMinimoDecimal/valorMinimoDecimal";
import possuiMesmoValor from "./possuiMesmoValor/possuiMesmoValor";
import { cpfOuCnpj, validarCpf, validarCnpj } from "./cpfOuCnpj/cpfOuCnpj";
import validaUrlYoutube from "./validaUrlYoutube/validaUrlYoutube";
import validaTelefone from "./validaTelefone/validaTelefone";

const rules = [
  {
    name: "cpfOuCnpj",
    rule: cpfOuCnpj,
  },
  {
    name: "validarCnpj",
    rule: validarCnpj,
  },
  {
    name: "validarCpf",
    rule: validarCpf,
  },
  {
    name: "placa",
    rule: placa,
  },
  {
    name: "chassi",
    rule: chassi,
  },
  {
    name: "renavam",
    rule: renavam,
  },
  {
    name: "valorMinimo",
    rule: valorMinimo,
  },
  {
    name: "valorMinimoDecimal",
    rule: valorMinimoDecimal,
  },
  {
    name: "possuiMesmoValor",
    rule: possuiMesmoValor,
  },
  {
    name: "validaUrlYoutube",
    rule: validaUrlYoutube,
  },
  {
    name: "telefone",
    rule: validaTelefone,
  },
];

export default rules;
export {
  cpfOuCnpj,
  validarCpf,
  validarCnpj,
  placa,
  renavam,
  chassi,
  valorMinimo,
  valorMinimoDecimal,
  possuiMesmoValor,
  validaUrlYoutube,
};
