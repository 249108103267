import { TBrandConfig } from "@core/services/interfaces/vehicle/brands/IBrandService";

const brandDefaultConfig: TBrandConfig = {
  // Endpoints
  getBrandByIdEndpoint:
    "/api/GerenciarVeiculos/Fabricante/ObterFabricantePorId",
  listBrandsEndpoint: "/api/GerenciarVeiculos/Fabricante/ListarFabricantes",
  updateBrandEndpoint: "/api/GerenciarVeiculos/Fabricante",
  createBrandEndpoint: "/api/GerenciarVeiculos/Fabricante",
  deleteBrandEndpoint: "/api/GerenciarVeiculos/Fabricante",
};

export default brandDefaultConfig;
