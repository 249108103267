import { IGeralImportConfig } from "@core/services/interfaces/geralEmplacamento/geralImport/IGeralImportService";

const importConfig: IGeralImportConfig = {
  // Endpoints
  requestValidateBrandEndpoint:
    "/api/Geral/Importacao/RequisitarValidacaoMarca",
  uploadBillingEndpoint: "/api/Geral/Importacao/UploadArquivo",
};

export default importConfig;
