import { Route } from "vue-router";
import ability from "./ability";

export const canNavigate: Function = ( to: Route ) : boolean => {
  return to.matched.some( ( route ) => ability.can(
      route.meta.action || "read",
      route.meta.resource
    ) || 
    route.meta.accessGranted 
  );
};