import { TResponseGetUserData } from "./interfaces/IUtil";
import { Timestamp } from "@firebase/firestore";
import { useAuth } from "@/services";

/**
 *
 * @returns {string | null}
 */
export const isUserLoggedIn = (): boolean => {
  return (
    localStorage.getItem(useAuth.serviceConfig.storageUserDataName) != null &&
    document.cookie.indexOf(useAuth.serviceConfig.storageRefreshTokenKeyName) >
      -1 &&
    document.cookie.indexOf(
      useAuth.serviceConfig.storageTokenKeyFirstPartName
    ) > -1 &&
    document.cookie.indexOf(
      useAuth.serviceConfig.storageTokenKeySecondPartName
    ) > -1
  );
};

/**
 *
 * @returns
 */
export const getUserData = (): TResponseGetUserData | {} =>
  JSON.parse(
    localStorage.getItem(useAuth.serviceConfig.storageUserDataName) || "{}"
  );

export const convertDateFromDateTime = (
  initialDate: Date | string,
  options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }
): string => {
  const date = new Date(initialDate);
  const formattedDate = date.toISOString().split("T")[0];
  const usDate = new Date(formattedDate);
  const brazilianDateFormat = usDate.toLocaleDateString("pt-BR", options);
  return brazilianDateFormat;
};

export const convertDateTimeToString = (
  initialDate: Date | string,
  locale = "pt-BR",
  options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "America/Sao_Paulo",
  }
): string => {
  const date = new Date(initialDate);
  const brazilianDateFormat = date.toLocaleDateString(locale, options);
  return brazilianDateFormat;
};

export const convertFromIsoDate = (isoDate: string): string => {
  const dateParts = isoDate.split("-"); // Split the date string into day, month, and year parts
  const day = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // Months are zero-based in JavaScript Date object
  const year = parseInt(dateParts[2]);

  const date = new Date(year, month, day);
  const brazilianDateFormat = date.toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return brazilianDateFormat;
};

export const convertToCSV = (data: Array<any>): string => {
  const header = Object.keys(data[0]).join(";") + "\n";
  const rows = data.map((obj) => Object.values(obj).join(";")).join("\n");
  return header + rows;
};

export const convertDateTimestampToString = (date: Timestamp): string => {
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];
  const notificationDate = date.toDate();
  const currentDate = new Date();

  if (
    notificationDate.getDate() === currentDate.getDate() &&
    notificationDate.getMonth() === currentDate.getMonth() &&
    notificationDate.getFullYear() === currentDate.getFullYear()
  ) {
    const hours =
      notificationDate.getHours() < 10
        ? "0" + notificationDate.getHours()
        : notificationDate.getHours();
    const minutes =
      notificationDate.getMinutes() < 10
        ? "0" + notificationDate.getMinutes()
        : notificationDate.getMinutes();

    return `${hours}:${minutes}`;
  } else {
    const day =
      notificationDate.getDate() < 10
        ? "0" + notificationDate.getDate()
        : notificationDate.getDate();

    return `${day} de ${months[notificationDate.getMonth()]}`;
  }
};

export const convertToUSDate = (brDate: string): string => {
  const [day, month, year] = brDate.split("/");
  return `${year}-${month}-${day}`;
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (
  userRole: string
): { name: string } | string => {
  if (userRole === "Administrador") return { name: "dashboard-kpi" };
  if (userRole === "Administrador Sistema") return { name: "dashboard-kpi" };
  if (userRole === "Admin Abracaf") return { name: "dashboard-kpi" };
  if (userRole === "Administrador Associação") return { name: "dashboard-kpi" };
  if (userRole === "Concessionário") return { name: "dashboard-kpi" };
  if (userRole === "Faturamento") return { name: "dashboard-kpi" };
  if (userRole === "Boletim Diário") return { name: "reports-daily-report" };
  if (userRole === "Validação da Marca")
    return { name: "admin-panel-brand-validation" };
  return { name: "reports-by-brands" };
};

export const showLoading = () => {
  const loadingBg = document.getElementById("loading-bg");
  if (loadingBg) {
    loadingBg.style.display = "block";
    loadingBg.style.zIndex = "999999";
  }
};
