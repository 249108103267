enum PLACEHOLDER {
  FILTRE_POR_AVALIADORES = "Filtre por avaliadores",
  FILTRE_POR_GRUPO = "Filtre por grupo",
  FILTRE_POR_LOJAS = "Filtre por lojas",
  FILTRE_POR_STATUS = "Filtre por status",
  FILTRAR_POR_LOJA = "Filtrar por loja",
  FILTRAR_POR_LOJAS = "Filtrar por lojas",
  FILTRAR_POR_AVALIADOR = "Filtrar por avaliador",
  FILTRAR_POR_SITUACAO = "Filtrar por situação",
  PESQUISE_POR_NOME = "Pesquise por nome",
  PESQUISE_POR_NOME_OU_DOCUMENTO = "Pesquise por nome ou documento",
  PESQUISE_PELO_NOME = "Pesquise pelo nome",
  PESQUISE_POR_PLACA = "Pesquise por placa",
  PESQUISE_A_MARCA = "Pesquise o nome da marca",
  PESQUISE_O_VENDEDOR = "Pesquise o nome da vendedor",
  PESQUISE_O_VEICULO = "Pesquise o nome do veículo",
  DIGITE_O_CNPJ = "Digite o CNPJ",
  DIGITE_O_CEP = "Digite o CEP",
  DIGITE_O_EMAIL = "Digite o e-mail",
  DIGITE_O_VALOR = "Digite o valor",
  DIGITE_O_CHASSI = "Digite o chassi",
  DIGITE_O_TELEFONE = "Digite o telefone",
  DIGITE_O_NOME_FANTASIA = "Digite o nome fantasia",
  DIGITE_O_RENAVAM = "Digite o renavam",
  DIGITE_CPF_OU_CPNJ = "Digite o CPF ou CNPJ",
  DESCREVA_ITEM_FALTANTE = "Descreva o item faltante",
  SELECIONE = "Selecione",
  SELECIONE_O_ESTADO = "Selecione o estado",
  SELECIONE_A_CIDADE = "Selecione a cidade",
  SELECIONE_A_MARCA = "Selecione a marca",
  SELECIONE_A_VERSAO = "Selecione a versão",
  SELECIONE_O_MODELO = "Selecione o modelo",
  SELECIONE_A_CATEGORIA = "Selecione a categoria",
  SELECIONE_A_COR = "Selecione a cor",
  SELECIONE_O_CAMBIO = "Selecione o câmbio",
  SELECIONE_O_ANO = "Selecione o ano",
  SELECIONE_O_COMBUSTIVEL = "Selecione o combustível",
  SELECIONE_AS_OPCIONAIS = "Selecione as opcionais",
  SELECIONE_O_MOTIVO = "Selecione o motivo",
  SELECIONE_AS_OBSERVACOES = "Selecione as observações",
  SELECIONE_ANO_FABRICACAO = "Selecione o ano fabricação",
  SELECIONE_ANO_MODELO = "Selecione o ano modelo",
  SELECIONE_TIPO_AVALIACAO = "Selecione o tipo da avaliação",
  SELECIONE_CLASSIFICACAO = "Selecione a classificação",
  SELECIONE_LOJA = "Selecione a loja",
  SELECIONE_FILIAL = "Selecione a filial",
  SELECIONE_SISTEMA = "Selecione o sistema",
  SELECIONE_DOMINIO = "Selecione o dominío",
  SELECIONE_AVALIADOR = "Selecione o avaliador",
  SELECIONE_VENDEDOR = "Selecione o vendedor",
  SELECIONE_O_TIPO_DA_OFERTA = "Selecione o tipo da oferta",
  SELECIONE_UMA_OPCAO = "Selecione uma opção",
  EXPECTATIVA_DO_CLIENTE = "Expectativa do cliente",
  INFORME_O_REGISTRO = "Informe o registro",
  INFORME_O_CODIGO = "Informe o código",
  INFORMACOES_ADICIONAIS = "Informações adicionais",
  QUILOMETRAGEM = "Quilometragem",
  DESCRICAO = "Descrição",
  OBSERVACAO = "Observação",
  VALOR = "Valor",
  NOME = "Nome",
  EMAIL = "Email",
  DE = "De",
  ATE = "Até",
  MIN = "Mín",
  MAX = "Máx",
  LOJA = "Loja",
  TELEFONE = "Telefone",
  PLACA = "Placa",
  PERFIS = "Perfis",
  LOJAS = "Lojas",
  BUSCAR = "Buscar...",
  BUSCANDO = "Buscando...",
  NENHUMA_NOTIFICACAO = "Nenhuma notificação disponível no momento.",
}

export default PLACEHOLDER;
