import actions from "./oldServiceActions";
import getters from "./oldServiceGetters";
import state from "./oldServiceState";
import mutations from "./oldServiceMutations";

export default {
  isRegistered: false,
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
