import { AxiosInstance } from "axios";
import AdministratorService from "./administratorService";

export default function useAdministrator(
  axiosIns: AxiosInstance,
  administratorOverrideConfig: Object
) {
  const administrator = new AdministratorService(
    axiosIns,
    administratorOverrideConfig
  );

  return {
    administrator,
  };
}
