import useAdministrator from "@core/services/administrator/useAdministrator";
import axios from "axios";
import defaultConfig from "../defaultConfig";

const axiosIns = axios.create({
  baseURL: defaultConfig.administrationService,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  },
});

const { administrator } = useAdministrator(axiosIns, {});
export default administrator;
