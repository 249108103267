import { IAdministratorConfig } from "@core/services/interfaces/administrator/IAdministratorService";

const administratorConfig: IAdministratorConfig = {
  // Endpoints
  getCodeEndpoint: "/api/Administracao/Usuario/RecuperarSenha/SolicitarCodigo",
  validateCodeEndpoint:
    "/api/Administracao/Usuario/RecuperarSenha/ValidarCodigo",
  saveNewPasswordEndpoint:
    "/api/Administracao/Usuario/RecuperarSenha/CadastrarNovaSenha",
  updatePasswordEndpoint: "/api/Administracao/Usuario/AlterarSenha",
  getProfilesEndpoint: "/api/Administracao/Perfil",
  createUserEndpoint: "/api/Administracao/Usuario",
  listEventsByProfilesAdmEmplacamentoEndpoint:
    "/api/Administracao/Evento/EventosPorPerfil",
  updateProfileAdmEmplacamentoEndpoint: "/api/Administracao/Perfil",
  includeProfileEventAdmEmplacamentoEndpoint:
    "/api/Administracao/PerfilEvento/IncluirAtualizarPerfilEvento",
  listUserEndpoint: "/api/Administracao/Usuario",
  requestPasswordResetEndpoint:
    "/api/Administracao/Usuario/SolicitarRedefinirSenha",
  updateUserEndpoint: "/api/Administracao/Usuario",
  updateLoginUserEndpoint: "/api/Administracao/Usuario/AlterarLogin",
  changePasswordEndpoint: "/api/Administracao/Usuario/AlterarSenha",
  activateInactivateUserEndpoint: "/api/Administracao/Usuario/AtivarInativar",
};

export default administratorConfig;
