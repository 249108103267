import { IAppConfigState, IParamConfig } from "./appConfigState";

export default {
  TOGGLE_RTL(state: IAppConfigState) {
    state.layout.isRTL = !state.layout.isRTL;
    document.documentElement.setAttribute(
      "dir",
      state.layout.isRTL ? "rtl" : "ltr"
    );
  },
  UPDATE_SKIN(state: IAppConfigState, skin: string) {
    state.layout.skin = skin;

    // Update value in localStorage
    localStorage.setItem("vuexy-skin", skin);

    // Update DOM for dark-layout
    if (skin === "dark") document.body.classList.add("dark-layout");
    else if (document.body.className.match("dark-layout"))
      document.body.classList.remove("dark-layout");
  },
  UPDATE_ROUTER_TRANSITION(state: IAppConfigState, val: string) {
    state.layout.routerTransition = val;
  },
  UPDATE_LAYOUT_TYPE(state: IAppConfigState, val: string) {
    state.layout.type = val;
  },
  UPDATE_CONTENT_WIDTH(state: IAppConfigState, val: string) {
    state.layout.contentWidth = val;
  },
  UPDATE_NAV_MENU_HIDDEN(state: IAppConfigState, val: boolean) {
    state.layout.menu.hidden = val;
  },
  UPDATE_NAVBAR_CONFIG(
    state: IAppConfigState,
    obj: { type: string; backgroundColor: string }
  ) {
    Object.assign(state.layout.navbar, obj);
  },
  UPDATE_FOOTER_CONFIG(state: IAppConfigState, obj: { type: string }) {
    Object.assign(state.layout.footer, obj);
  },
  SET_PARAMS(state: IAppConfigState, params: IParamConfig) {
    state.params = params;
  },
};
