import useDealerships from "@core/services/business/dealerships/useDealerships";
import axios from "axios";
import defaultConfig from "@/services/defaultConfig";

const axiosIns = axios.create({
  baseURL: defaultConfig.businessService,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  },
});

const { profile } = useDealerships(axiosIns, {});
export default profile;
