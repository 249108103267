import { Commit } from 'vuex/types/index';

export default {
  fetchSliders( { commit }: { commit: Commit } ) {
    const dataFake = {
      data: [
        {
          id              : 1,
          title           : "Slider",
          active_site     : false,
          active_extranet : true
        },
        {
          id              : 2,
          title           : "Time Avonale 01",
          active_site     : true,
          active_extranet : true
        },
        {
          id              : 3,
          title           : "Time Avonale 002",
          active_site     : true,
          active_extranet : false
        },
      ],
      draw            : 1,
      recordsTotal    : 3,
      recordsFiltered : 3,
      lastPage        : 1
    };

    return new Promise( ( resolve ) => {
      resolve( dataFake );
    } )
  },
}
