import actions from "./segmentsActions";
import getters from "./segmentsGetters";
import state from "./segmentsState";
import mutations from "./segmentsMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};