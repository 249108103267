import AuthService from "@core/services/auth/authService";
import defaultConfig from "./brandValidationDefaultConfig";
import { AxiosInstance } from "axios";
import {
  IBodyInitiate,
  IBodyList,
  IResponseDeleteBrandValidation,
  IResponseInitiateBrandValidation,
  IResponseListBrandValidations,
  IValidationService,
  IValidationTypeOption,
} from "../interfaces/brand-validation/IValidationService";
import { TValidationConfig } from "../interfaces/brand-validation/IValidationService";

export default class BrandValidationService
  extends AuthService
  implements IValidationService
{
  brandValidationServiceConfig: TValidationConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.brandValidationServiceConfig = { ...defaultConfig, ...overrideConfig };
  }

  requestGetBrandValidationType(): IValidationTypeOption[] {
    const currentUser = this.getCurrentUser();

    if (currentUser?.ehAdmin) {
      return [
        { label: "Diário", value: 1 },
        { label: "Trimestral", value: 2 },
        { label: "Padrão", value: 3 },
        { label: "Detalhado", value: 4 },
      ];
    } else {
      return [{ label: "Padrão", value: 3 }];
    }
  }

  requestDelete(
    brandValidationId: number
  ): Promise<IResponseDeleteBrandValidation> {
    return this.axiosIns.delete(
      this.brandValidationServiceConfig.deleteValidationEndpoint +
        "?id=" +
        brandValidationId
    );
  }

  requestInitiate(
    initiateBody: IBodyInitiate
  ): Promise<IResponseInitiateBrandValidation> {
    return this.axiosIns.post(
      this.brandValidationServiceConfig.initiateValidationEndpoint,
      initiateBody
    );
  }

  requestList(body: IBodyList): Promise<IResponseListBrandValidations> {
    const paginate = `?paginar=${body.paginate ? body.paginate : "false"}`;

    return this.axiosIns.post(
      this.brandValidationServiceConfig.listValidationEndpoint + paginate,
      body
    );
  }
}
