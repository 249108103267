import actions from "./modelActions";
import getters from "./modelGetters";
import state from "./modelState";
import mutations from "./modelMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};