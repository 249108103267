import { IAuthConfig } from "@core/services/interfaces/auth/IAuthService";

const authConfig: IAuthConfig = {
  // Endpoints
  loginEndpoint: "/Connect/Token",
  changeTokenEndpoint: "/Connect/ChangeToken",
  refreshEndpoint: "/Connect/refreshtoken",

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyFirstPartName: "ApiToken-1",
  storageTokenKeySecondPartName: "ApiToken-2",
  storageRefreshTokenKeyName: "refreshToken",
  storageExpiresAtKeyName: "expiresAtToken",
  storageDomainKeyName: "domain",
  storagePurgeCacheName: "purge-cache",
  storagePurgeCacheNotCleanValue: "not-clean",
  storagePurgeCacheCleanValue: "clean",

  // Valor da localStore com as informações do usuário
  storageUserDataName: "userData",
  storageParamsName: "params",
  storageNotificarLogout: "notificarLogout",
};

export default authConfig;
