const messages = {
  required: (field) => `O campo ${field} precisa ser informado`,
  required_if: (field) => `O campo ${field} precisa ser informado`,
  alpha: (field) => `O campo ${field} só pode conter caracteres alfa-numéricos`,
  alpha_spaces: (field) =>
    `O campo ${field} só pode conter caracteres alfa-numéricos ou espaços`,
  length: (field, [length]) =>
    `O campo ${field} precisa ter exatamente ${length} caracteres`,
  email: (field) => `O campo ${field} não possui um email válido`,
  numeric: (field) =>
    `O campo ${field} deve possuir somente caracteres numéricos`,
  digits: (field, [length]) =>
    `O campo ${field} deve ser numérico e possuir ${length} digitos`,
  cpfOuCnpj: () => `Documento inválido`,
  chassi: () => `Insira um chassi válido`,
  renavam: () => `Insira um renavam válido`,
  validarCpf: () => `Documento inválido`,
  validarCnpj: () => `Documento inválido`,
  placa: () => `Insira uma placa válida`,
  telefone: (field) => `O valor inserido no campo ${field} não é válido`,
  min: (field, [length]) =>
    `O campo ${field} deve possuir no mínimo ${length} caracteres`,
  max: (field, [length]) =>
    `O campo ${field} deve possuir no máximo ${length} caracteres`,
  max_value: (field, [maxValue]) =>
    `O valor de ${field} deve ser no máximo ${maxValue}`,
  min_value: (field, [minValue]) =>
    `O valor de ${field} deve ser no mínimo ${minValue}`,
  date_format: (field) => `Insira uma data válida no campo ${field}`,
  before: (field, [target]) =>
    `A data do campo '${field}' deve ser menor que a do campo '${target}'`,
  after: (field, [target]) =>
    `A data do campo '${field}' deve ser maior que a do campo '${target}'`,
};

const names = {
  phone: "Telefone",
  birth_date: "Data de Nascimento",
  name: "Nome",
  complete_name: "Nome Completo",
  mother_name: "Nome da mãe",
  mother_RG: "RG da mãe",
  mother_CPF: "CPF da mãe",
  father_name: "Nome do pai",
  father_RG: "RG do pai",
  father_CPF: "CPF do pai",
  year: "Ano",
  model: "Modelo",
  description: "Descrição",
};

export { messages, names };
export default {
  messages,
  names,
};
