import useArea from "@core/services/covarege-area/area/useArea";
import axios from "axios";
import defaultConfig from "../../defaultConfig";

// defaultConfig.authenticationService
const axiosIns = axios.create({
  baseURL: defaultConfig.coverageAreaService,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  },
});

const { area } = useArea(axiosIns, {});
export default area;
