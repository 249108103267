import AuthService from "@core/services/auth/authService";
import defaultConfig from "./oldServiceDefaultConfig";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  IBodyGeneratePendingToSendCollectionLetter,
  IBodyUpdateBillingParameters,
  IImportBilling,
  IImportEmplacamento,
  IOldService,
  IOldServiceConfig,
  IResponseCreateBillingEmplacamento,
  IResponseCreateImportEmplacamento,
  IResponseDeleteFile,
  IResponseGeneratePendingToSendCollectionLetter,
  IResponseGetBillingEmplacamento,
  IResponseGetBrandEvolution,
  IResponseGetBusinessWithBilling,
  IResponseGetDailyBrandEvolution,
  IResponseGetDirectSaleVsRetailSaleBrand,
  IResponseGetDirectSaleVsRetailSaleYear,
  IResponseGetFile,
  IResponseGetEffectivateBilling,
  IResponseGetGrowthByBrand,
  IResponseGetImportEmplacamento,
  IResponseGetLargestEconomicGroupsYear,
  IResponseGetTotalMarketShareCurrentMonth,
  IResponseGetTotalMarketShareYearToDate,
  IResponseUpdateBillingParameters,
  IResponseUpdateDashboard,
  IResponseUploadFile,
} from "@core/services/interfaces/oldService/IOldService";

export default class OldService extends AuthService implements IOldService {
  serviceOldConfig: IOldServiceConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceOldConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * ========= DASHBOAD KPI ==========
   */
  requestGetTotalMarketShareCurrentMonth(): Promise<IResponseGetTotalMarketShareCurrentMonth> {
    return this.axiosIns.get(
      this.serviceOldConfig.getTotalMarketShareCurrentMonthEndpoint
    );
  }

  requestGetTotalMarketShareYearToDate(): Promise<IResponseGetTotalMarketShareYearToDate> {
    return this.axiosIns.get(
      this.serviceOldConfig.getTotalMarketShareYearToDateEndpoint
    );
  }

  requestGetLargestEconomicGroupsYear(): Promise<IResponseGetLargestEconomicGroupsYear> {
    return this.axiosIns.get(
      this.serviceOldConfig.getLargestEconomicGroupsYearEndpoint
    );
  }

  requestGetGrowthByBrand(): Promise<IResponseGetGrowthByBrand> {
    return this.axiosIns.get(this.serviceOldConfig.getGrowthByBrandEndpoint);
  }

  requestGetDirectSaleVsRetailSaleBrand(): Promise<IResponseGetDirectSaleVsRetailSaleBrand> {
    return this.axiosIns.get(
      this.serviceOldConfig.getDirectSaleVsRetailSaleBrandEndpoint
    );
  }

  requestGetDirectSaleVsRetailSaleYear(): Promise<IResponseGetDirectSaleVsRetailSaleYear> {
    return this.axiosIns.get(
      this.serviceOldConfig.getDirectSaleVsRetailSaleYearEndpoint
    );
  }

  requestGetBrandEvolution(): Promise<IResponseGetBrandEvolution> {
    return this.axiosIns.get(this.serviceOldConfig.getBrandEvolutionEndpoint);
  }

  requestGetDailyBrandEvolution(): Promise<IResponseGetDailyBrandEvolution> {
    return this.axiosIns.get(
      this.serviceOldConfig.getDailyBrandEvolutionEndpoint
    );
  }

  requestUpdateDashboard(): Promise<IResponseUpdateDashboard> {
    return this.axiosIns.get(this.serviceOldConfig.updateDashboardEndpoint);
  }

  requestGetImportEmplacamento(): Promise<IResponseGetImportEmplacamento> {
    return this.axiosIns.post(
      this.serviceOldConfig.getImportEmplacamentoEndpoint + "?guid=true"
    );
  }

  requestGetBillingEmplacamento(): Promise<IResponseGetBillingEmplacamento> {
    return this.axiosIns.post(
      this.serviceOldConfig.getBillingEmplacamentoEndpoint
    );
  }

  requestUploadFile(
    file: File,
    config?: AxiosRequestConfig
  ): Promise<IResponseUploadFile> {
    const formData = new FormData();
    formData.append("files[]", file);

    return this.axiosIns.post(
      this.serviceOldConfig.uploadFileEndpoint,
      formData,
      config
    );
  }

  requestDeleteFile(fileName: string): Promise<IResponseDeleteFile> {
    return this.axiosIns.delete(
      this.serviceOldConfig.deleteFileEndpoint + `?file=${fileName}`
    );
  }

  requestGetFile(fileName: string): Promise<IResponseGetFile> {
    return this.axiosIns.get(
      this.serviceOldConfig.getFileEndpoint + `?file=${fileName}`
    );
  }

  requestCreateImportEmplacamento(
    imports: IImportEmplacamento[]
  ): Promise<IResponseCreateImportEmplacamento> {
    return this.axiosIns.post(
      this.serviceOldConfig.createImportEmplacamentoEndpoint,
      imports
    );
  }

  requestCreateImportBilling(
    imports: IImportBilling
  ): Promise<IResponseCreateBillingEmplacamento> {
    return this.axiosIns.post(
      this.serviceOldConfig.createImportBillingEndpoint,
      imports
    );
  }

  requestGetEffectivateBilling(): Promise<IResponseGetEffectivateBilling> {
    return this.axiosIns.get(
      this.serviceOldConfig.getEffectivateBillingEndpoint
    );
  }

  requestGetBusinessWithBilling(
    year: number,
    month: number
  ): Promise<IResponseGetBusinessWithBilling> {
    const formData = new FormData();
    formData.append("AnoSelecionado", "" + year);
    formData.append("MesSelecionado", "" + month);

    return this.axiosIns.post(
      this.serviceOldConfig.getBusinessWithBillingEndpoint,
      formData
    );
  }

  requestGeneratePendingToSendCollectionLetter(
    body: IBodyGeneratePendingToSendCollectionLetter,
    forceResend: boolean = false
  ): Promise<IResponseGeneratePendingToSendCollectionLetter> {
    return this.axiosIns.post(
      this.serviceOldConfig.generatePendingToSendCollectionLetterEndpoint +
        `?forcarReenvio=${forceResend}`,
      body
    );
  }

  requestUpdateBillingParameters(
    body: IBodyUpdateBillingParameters
  ): Promise<IResponseUpdateBillingParameters> {
    return this.axiosIns.post(
      this.serviceOldConfig.updateBillingParametersEndpoint,
      body
    );
  }
}
