import AuthService from "@core/services/auth/authService";
import defaultConfig from "./calendarDefaultConfig";
import { AxiosInstance } from "axios";
import {
  ICalendarService,
  ICalendarConfig,
  IResponseGetBlockedDates,
  IResponseIsWorkingDay,
} from "@core/services/interfaces/geralEmplacamento/calendar/ICalendarService";

export default class CalendarService
  extends AuthService
  implements ICalendarService
{
  serviceCalendarConfig: ICalendarConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceCalendarConfig = { ...defaultConfig, ...overrideConfig };
  }

  requestGetBlockedDates(startDate: string): Promise<IResponseGetBlockedDates> {
    return this.axiosIns.get(
      this.serviceCalendarConfig.getBlockedDatesEndpoint +
        `?dataInicial=${startDate}`
    );
  }

  requestIsWorkingDay(date: string): Promise<IResponseIsWorkingDay> {
    return this.axiosIns.get(
      this.serviceCalendarConfig.isWorkingDayEndpoint + `?data=${date}`
    );
  }
}
