export interface IEventsState {
  calendarOptions: Array<{
    color: string,
    label: string
  }>,
  selectedCalendars: Array<string>
}

let eventsState: IEventsState = {
  calendarOptions: [
    {
      color: 'danger',
      label: 'Personal',
    },
    {
      color: 'primary',
      label: 'Business',
    },
    {
      color: 'warning',
      label: 'Family',
    },
    {
      color: 'success',
      label: 'Holiday',
    },
    {
      color: 'info',
      label: 'ETC',
    },
  ],
  selectedCalendars: [ 'Personal', 'Business', 'Family', 'Holiday', 'ETC' ],
};

export default eventsState;

export type TResponseDistricts = {
  data: Array<{
    Id      : number,
    name    : string,
    codigo  : string,
  }>,
  draw            : number,
  recordsTotal    : number,
  recordsFiltered : number,
  lastPage        : number
}
