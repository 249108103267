//Modules
import BUTTON from "./modules/Button/enumStringsButton";
import LABEL from "./modules/Label/enumStringsLabel";
import PLACEHOLDER from "./modules/Placeholder/enumStringsPlaceholder";
import ICON from "./modules/Icon/enumStringsIcon";
import ROUTE from "./modules/Route/enumStringsRoute";
import TEXT from "./modules/Text/enumStringsText";
import TOOLTIP from "./modules/Tooltip/enumStringsTooltip";

//Interface
import { IEnumModels } from "./interfaces/IEnumModels";
const ENUMS: IEnumModels = {
  BUTTON,
  LABEL,
  PLACEHOLDER,
  ICON,
  ROUTE,
  TEXT,
  TOOLTIP,
};
export default ENUMS;
