import AuthService from "@core/services/auth/authService";
import defaultConfig from "./cacheDefaultConfig";
import { AxiosInstance } from "axios";
import {
  ICacheService,
  IResponseGetCache,
  IResponseDeleteCache,
  TCacheConfig,
} from "@core/services/interfaces/geralEmplacamento/cache/ICacheService";

export default class CacheService extends AuthService implements ICacheService {
  serviceCacheConfig: TCacheConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceCacheConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Consultando cache do usuário logado
   * Obs.: caso ainda não exista cache ele é criado e retornado, se já existir apenas retorna
   * Obs. 2: pelo fato desta rota consultar cache é ligeiramente mais rápida que as demais.
   */
  requestGet(): Promise<IResponseGetCache> {
    return this.axiosIns.get(this.serviceCacheConfig.getCacheEndpoint);
  }

  /**
   * Manda requisição para apagar o cache do usuário.
   */
  requestDelete(): Promise<IResponseDeleteCache> {
    return this.axiosIns.delete(this.serviceCacheConfig.deleteCacheEndpoint);
  }
}
