import useParam from "@core/services/geral/param/useParam";
import axios from "axios";
import defaultConfig from "../../defaultConfig";

const axiosIns = axios.create({
  baseURL: defaultConfig.generalService,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  },
});

const { param } = useParam(axiosIns, {});
export default param;
