export default [
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/pages/authentication/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/confirm-code",
    name: "auth-confirm-code",
    component: () => import("@/views/pages/authentication/ConfirmCode.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/reset-password",
    name: "auth-reset-password-v1",
    component: () => import("@/views/pages/authentication/ResetPassword.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/coming-soon",
    name: "misc-coming-soon",
    component: () => import("@/views/pages/miscellaneous/ComingSoon.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/miscellaneous/under-maintenance",
    name: "misc-under-maintenance",
    component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/error",
    name: "misc-error",
    component: () => import("@/views/pages/miscellaneous/Error.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/notifications",
    name: "notifications-list",
    meta: {
      pageTitle: "Notificações",
      resource: "Logged",
    },
    component: () =>
      import("@/views/pages/notifications/NotificationsList.vue"),
  },
];
