import { AxiosInstance } from "axios";
import OldService from "./oldService";

export default function useOldService(
  axiosIns: AxiosInstance,
  overrideConfig: Object
) {
  const oldService = new OldService(axiosIns, overrideConfig);

  return {
    oldService,
  };
}
