import { $themeConfig } from '@themeConfig';

export interface IVerticalMenuState {
  isVerticalMenuCollapsed: boolean,
}

let verticalMenuState: IVerticalMenuState = {
  isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed
}

export default verticalMenuState;
