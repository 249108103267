import { AxiosInstance } from "axios";
import BrandValidationService from "./brandValidationService";

export default function useBrandValidation(
  axiosIns: AxiosInstance,
  brandOverrideConfig: Object
) {
  const area = new BrandValidationService(axiosIns, brandOverrideConfig);

  return {
    area,
  };
}
