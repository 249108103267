import { TAreaConfig } from "@/@core/services/interfaces/covarege-area/area/IAreaService";

const areaConfig: TAreaConfig = {
  //Endpoints
  listAreaEndpoint:
    "/api/AreasAbrangencia/AreaInfluencia/ListarAreasInfluencia",
  getAreaByIdEndpoint:
    "/api/AreasAbrangencia/AreaInfluencia/ObterAreaInfluenciaPorId",
  addAreaEndpoint:
    "/api/AreasAbrangencia/AreaInfluencia/AdicionarAreaInfluencia",
  editAreaEndpoint:
    "/api/AreasAbrangencia/AreaInfluencia/AtualizarAreaInfluencia",
  deleteAreaEndpoint:
    "/api/AreasAbrangencia/AreaInfluencia/RemoverAreaInfluencia",
  listInfluencedCitiesAreaEndpoint:
    "/api/AreasAbrangencia/AreaInfluencia/ListarMunicipiosInfluenciados",
  listInfluenceAreasScopeEndpoint:
    "/api/AreasAbrangencia/AreaInfluencia/ObterAreasInfluenciaEscopo",
};

export default areaConfig;
