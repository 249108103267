enum ROUTE {
  //DEFAULT ROUTES
  LOGIN = "/login/",
  ESQUECI_SENHA = "/esqueci-senha/",
  CADASTRAR_NOVA_SENHA = "/cadastrar-nova-senha/",
  CADASTRO = "/cadastro/",
  PAGINA_NAO_ENCONTRADA = "/pagina-nao-encontrada/",
  QUALQUER_PAGINA = "*",
  NAO_AUTORIZADO = "/nao-autorizado/",
  //DASHBOARD
  DEFAULT = "/dashboard/ecommerce",
  DEFAULT_REDIRECT = "/ashboard/ecommerce",
  //VEICULOS
  VEICULOS = "/veiculos/",
  VEICULOS_REDIRECT = "/veiculos/showroom/",
  VEICULOS_REPASSE = "/veiculos/repasse/",
  VEICULOS_SHOWROOM = "/veiculos/showroom/",
  VISUALIZAR_VEICULO = "/veiculos/showroom/visualizar-veiculo/:veiculoId",
  CRIAR_OFERTA = "/veiculos/criar-oferta/",
  CRIAR_OFERTA_ID = "/veiculos/criar-oferta/:veiculoId",
  // NEGOCIOS
  NEGOCIOS = "/negocios/",
  NEGOCIOS_REDIRECT_GERENCIAR_OFERTAS = "/negocios/gerenciar-ofertas/",
  NEGOCIOS_REDIRECT_OFERTAS = "/negocios/ofertas/",
  NEGOCIOS_COMPRAS = "/negocios/compras/",
  NEGOCIOS_OFERTAS_LOJISTA = "/negocios/ofertas-lojista/",
  NEGOCIOS_VENDAS = "/negocios/vendas/",
  NEGOCIOS_FAVORITOS = "/negocios/favoritos/",
  NEGOCIOS_GERENCIAR_OFERTAS = "/negocios/gerenciar-ofertas/",
  NEGOCIOS_OFERTAS = "/negocios/ofertas/",
  NEGOCIOS_VISUALIZAR_OFERTA_ID = "/negocios/visualizar-oferta/:ofertaId",
  NEGOCIOS_VISUALIZAR_OFERTA = "/negocios/visualizar-oferta/",
  //RELATORIOS
  RELATORIOS = "/relatorios/",
  RELATORIOS_REDIRECT = "/relatorios/avaliacao/",
  RELATORIOS_AVALIACAO = "/relatorios/avaliacao/",
  RELATORIOS_REDIRECT_AVALIACAO = "/relatorios/avaliacao/tipos-avaliacao/",
  RELATORIOS_TIPOS_AVALIACAO = "/relatorios/avaliacao/tipos-avaliacao/",
  RELATORIOS_CAPTACAO = "/relatorios/avaliacao/captacao/",
  RELATORIOS_TEMPO_DE_MESA = "/relatorios/avaliacao/tempo-de-mesa/",
  RELATORIOS_VALORACAO = "/relatorios/avaliacao/valoracao/",
  RELATORIOS_VENDAS = "/relatorios/ofertas/vendas/",
  RELATORIOS_OFERTAS_DEFAULT = "/relatorios/ofertas/",
  RELATORIOS_OFERTAS_REDIRECT = "/relatorios/ofertas/vendas/",
  RELATORIOS_OFERTAS = "/relatorios/ofertas/vendas/",
  RELATORIOS_VEICULOS = "/relatorios/veiculos/",
  RELATORIOS_VEICULOS_GERAL = "/relatorios/veiculos/geral/",
  RELATORIOS_VEICULOS_REDIRECT = "/relatorios/veiculos/geral/",
  //ADMINISTRATIVO
  ADMINISTRATIVO = "/administrativo/",
  ADMINISTRATIVO_CONFIGURACOES = "/administrativo/configuracoes/",
  ADMINISTRATIVO_REDIRECT = "/administrativo/configuracoes/",
  ADMINISTRATIVO_REDIRECT_USUARIOS = "/administrativo/configuracoes/usuarios/",
  ADM_PREFERENCIA_ALERTA = "/administrativo/configuracoes/preferencia-alerta/",
  ADM_AUTORIZAR_GRUPO = "/administrativo/configuracoes/autorizar-grupo/",
  ADM_GERENCIAR_GRUPOS = "/administrativo/configuracoes/gerenciar-grupos/",
  ADM_USUARIOS = "/administrativo/configuracoes/usuarios/",
  ADM_EMPRESAS = "/administrativo/configuracoes/empresas/",
  ADM_LOJAS = "/administrativo/configuracoes/lojas/",
  ADM_PARAMETROS = "/administrativo/configuracoes/gerenciarParametros/",
  ADM_PARAMETROS_FILIAL_ID = "/gerenciarParametroFilial/:id",
  ADM_PARAMETROS_FILIAL = "/administrativo/configuracoes/gerenciarParametroFilial/",
  ADM_CONSULTAR_LOJISTA = "/administrativo/configuracoes/consultarLojista/",
  ADM_EVENTOS = "/administrativo/configuracoes/eventos/",
  ADM_MOTIVOS_DE_CANCELAMENTO = "/administrativo/configuracoes/motivos-de-cancelamento/",
  ADM_MOTIVOS_DE_DESCARTE = "/administrativo/configuracoes/motivos-de-descarte/",
  ADM_ENCERRAR_CONTA = "/administrativo/configuracoes/encerrarConta/",
  ADM_AGRUPAMENTOS = "/administrativo/configuracoes/gerenciar-agrupamentos/",
  ADM_GERENCIAR_LOGIN = "/administrativo/configuracoes/gerenciar-usuarios/",
  ADM_TIPO_DE_AVALIACAO = "/administrativo/configuracoes/tipo-de-avaliacao/",
  ADM_EDITAR_ACESSO_ID = "/editar-acesso/:id",
  ADM_EDITAR_ACESSO = "/administrativo/configuracoes/editar-acesso/",
  ADM_DE_PARA = "/administrativo/configuracoes/de-para/",
}

export default ROUTE;
