import AuthService from "@core/services/auth/authService";
import defaultConfig from "./administratorDefaultConfig";
import { AxiosInstance, AxiosResponse } from "axios";
import {
  IAdministratorConfig,
  IAdministratorService,
  IResponseGetCode,
  IBodyValidateCode,
  IResponseValidateCode,
  IBodySaveNewPassword,
  IResponseSaveNewPassword,
  IResponseUpdatePassword,
  IBodyUpdatePassword,
  IBodyCreateUser,
  IResponseCreateUser,
  IBodyGetProfiles,
  IResponseGetProfiles,
  IResponseListEventsByProfile,
  IUpdateProfile,
  IResponseUpdateProfile,
  IBodyListUser,
  IResponseListUser,
  IResponseRequestPasswordReset,
  IBodyUpdateUser,
  IResponseUpdateUser,
  IResponseUpdateLoginUser,
  IChangePassword,
  IResponseChangePassword,
  IResponseActivateInactivateUser,
  IProfile,
} from "@core/services/interfaces/administrator/IAdministratorService";
import { IUserData } from "../interfaces/IUtil";

export default class AdministratorService
  extends AuthService
  implements IAdministratorService
{
  serviceAdministratorConfig: IAdministratorConfig;
  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceAdministratorConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Requisição para solicitar o código do usuário
   */
  requestGetCode(email: string): Promise<IResponseGetCode> {
    return this.axiosIns.post(this.serviceAdministratorConfig.getCodeEndpoint, {
      email,
    });
  }

  /**
   * Requisição para validar o código do usuário
   */
  requetValidateCode(body: IBodyValidateCode): Promise<IResponseValidateCode> {
    return this.axiosIns.post(
      this.serviceAdministratorConfig.validateCodeEndpoint,
      body
    );
  }

  /**
   * Requisição para alterar a senha
   */
  requestSaveNewPassword(
    body: IBodySaveNewPassword
  ): Promise<IResponseSaveNewPassword> {
    return this.axiosIns.post(
      this.serviceAdministratorConfig.saveNewPasswordEndpoint,
      body
    );
  }

  /**
   * Requisição para alterar a senha
   */
  requestUpdatePassword(
    body: IBodyUpdatePassword
  ): Promise<IResponseUpdatePassword> {
    return this.axiosIns.post(
      this.serviceAdministratorConfig.updatePasswordEndpoint,
      body
    );
  }

  // ====== PROFILE ======

  /**
   * Retorna uma lista eventos
   */
  requestListEventsByProfile(
    profileId: string
  ): Promise<IResponseListEventsByProfile> {
    return this.axiosIns.get(
      this.serviceAdministratorConfig
        .listEventsByProfilesAdmEmplacamentoEndpoint +
        "?perfilId=" +
        profileId
    );
  }

  requestGetProfiles(
    body?: IBodyGetProfiles
  ): Promise<AxiosResponse<IResponseGetProfiles>> {
    return this.axiosIns
      .get(this.serviceAdministratorConfig.getProfilesEndpoint, {
        params: body,
      })
      .then(
        (
          response: AxiosResponse<IResponseGetProfiles>
        ): AxiosResponse<IResponseGetProfiles> => {
          const params = JSON.parse(localStorage.getItem("params") || "null");
          const currentUser: IUserData | null = this.getCurrentUser();
          const domain = localStorage.getItem("domain");

          if (currentUser && params) {
            const profilesAdmin: string[] =
              params["ABRACAF_CODIGO_PERFIL_ADMIN"].split(";");
            const profilesGroup: string[] =
              params["ABRACAF_CODIGO_PERFIL_GRUPO"].split(";");
            const profilesStore: string[] =
              params["ABRACAF_CODIGO_PERFIL_LOJA"].split(";");

            if (
              currentUser.ehAdmin ||
              profilesAdmin.includes(currentUser.profileId)
            ) {
              return response;
            }

            let profiles: IProfile[] = [];
            if (profilesGroup.includes(currentUser.profileId)) {
              profiles = response.data.data.filter(
                (profile: IProfile) =>
                  profilesGroup.includes(profile.id) ||
                  profilesStore.includes(profile.id)
              );
            } else {
              profiles = response.data.data.filter((profile: IProfile) =>
                profilesStore.includes(profile.id)
              );
            }

            // Um usuário com que não tenha perfil Admin não pode adicionar/editar usuários com perfil concessionária grupo Dealer
            if (domain == "Abracaf" && !currentUser.role.includes('Admin')) 
            {
              profiles = profiles.filter(
                (it) => it.nome != "Concessionária Grupo Dealer"
              );
            }

            return {
              ...response,
              data: {
                ...response.data,
                data: profiles,
              },
            };
          } else {
            throw new Error(
              "Os parâmetros do sistema não foram carregados corretamente!"
            );
          }
        }
      );
  }

  /**
   * Atualiza um evento e os perfis eventos de um perfil.
   */
  requestUpdateProfile(
    profile: IUpdateProfile
  ): Promise<IResponseUpdateProfile> {
    return this.axiosIns.put(
      this.serviceAdministratorConfig.updateProfileAdmEmplacamentoEndpoint,
      profile
    );
  }

  // ====== USERS ======

  requestCreateUser(user: IBodyCreateUser): Promise<IResponseCreateUser> {
    return this.axiosIns.post(
      this.serviceAdministratorConfig.createUserEndpoint,
      {
        ...user,
        dadosAdicionais: JSON.stringify(user.dadosAdicionais),
      }
    );
  }

  requestUpdateUser(user: IBodyUpdateUser): Promise<IResponseUpdateUser> {
    return this.axiosIns.put(
      this.serviceAdministratorConfig.updateUserEndpoint,
      {
        ...user,
        dadosAdicionais: JSON.stringify(user.dadosAdicionais),
      }
    );
  }

  requestUpdateLoginUser(
    userId: string,
    login: string
  ): Promise<IResponseUpdateLoginUser> {
    return this.axiosIns.patch(
      this.serviceAdministratorConfig.updateLoginUserEndpoint,
      {
        usuarioId: userId,
        login: login,
      }
    );
  }

  requestListUser(body?: IBodyListUser): Promise<IResponseListUser> {
    return this.axiosIns.get(this.serviceAdministratorConfig.listUserEndpoint, {
      params: body,
    });
  }

  requestRequestPasswordReset(
    usuarioId: string
  ): Promise<IResponseRequestPasswordReset> {
    return this.axiosIns.patch(
      this.serviceAdministratorConfig.requestPasswordResetEndpoint +
        `?usuarioId=${usuarioId}`
    );
  }

  /**
   * Atualiza a senha de um usuário.
   * SE a senha for a do usuário logado o campo senha é obrigatório e o campo usuárioId NÃO;
   * SE a senha for de outro usuário é permitido apenas se o usuário logado for administrador
   * do sistema e o campo Senha não é obrigatório
   */
  requestChangePassword(
    data: IChangePassword
  ): Promise<IResponseChangePassword> {
    return this.axiosIns.post(
      this.serviceAdministratorConfig.changePasswordEndpoint,
      data
    );
  }

  /**
   * Atualiza o status de um usuário pendente
   */
  requestActivateInactivateUser(
    id: string
  ): Promise<IResponseActivateInactivateUser> {
    return this.axiosIns.put(
      this.serviceAdministratorConfig.activateInactivateUserEndpoint,
      `"${id}"`
    );
  }
}
