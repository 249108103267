export default [
  {
    path: "/ui/feather",
    name: "ui-feather",
    component: () => import("@/views/ui/feather/Feather.vue"),
    meta: {
      pageTitle: "Feather",
      breadcrumb: [
        {
          text: "UI",
        },
        {
          text: "Feather",
          active: true,
        },
      ],
    },
  },
];
