function possuiMesmoValor(valorMaximo: string, valorMinimo: number[]): boolean {
  let valorMaximoFormatado;
  if (valorMaximo) {
    valorMaximoFormatado = Number(valorMaximo.replace(/\D/g, ""));
  }
  if (valorMaximoFormatado !== Number(valorMinimo[0]) && valorMaximoFormatado !== 0) {
    return true;
  }

  return false;
}

export default possuiMesmoValor;
