import actions from "./eventsActions";
import getters from "./eventsGetters";
import state from "./eventsState";
import mutations from "./eventsMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};