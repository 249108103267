import useNomenclature from "@core/services/vehicle/nomenclature/useNomenclature";
import axios from "axios";
import defaultConfig from "../../defaultConfig";

const axiosIns = axios.create({
  baseURL: defaultConfig.vehicleService,
});

const { nomenclature } = useNomenclature(axiosIns, {});
export default nomenclature;
