import { IStates, IStateState } from "./stateState";

export default {
  /**
   * Recebe uma lista de municípios e set no state Counties
   */
  SET_COUNTY(
    state: IStateState,
    counties: IStates[]
  ) : void {
    state.counties = counties;
  }
};