import { useAuth } from "@/services";
import { IAppSSO, IAppSSOConfig } from "@/services/interfaces/IAppSSO";

class AppSSO implements IAppSSO {
  config: IAppSSOConfig = {
    ssoQueryNameParam: "client_id",
    redirectToNameParam: "redirect_url",
    codeNameParam: "code",
    systems: {
      emplacamentoPortal: "EmplacamentoPortal",
      emplacamento: "emplacamento",
    },
  };
  socialLogin = false;

  setSocialLogin(value: boolean): void {
    this.socialLogin = value;
  }

  checkParamsContainSocialLogin(queryParams: any): boolean {
    const ssoQueryParam = queryParams[this.config.ssoQueryNameParam];

    if (this.checkParamIsEmpty(ssoQueryParam)) {
      return false;
    } else {
      return true;
    }
  }

  redirectUserToSocialLogin(queryParams: any, jwtToken: string): void {
    let redirectTo = decodeURIComponent(
      queryParams[this.config.redirectToNameParam]
    );
    const ssoId = queryParams[this.config.ssoQueryNameParam];

    if (!this.checkParamIsEmpty(redirectTo) && !this.checkParamIsEmpty(ssoId)) {
      const codeQueryParam = `${this.config.codeNameParam}=${jwtToken}`;

      redirectTo +=
        (redirectTo.indexOf("?") !== -1 ? "&" : "?") + codeQueryParam;

      window.location.href = redirectTo;
    }
  }

  checkParamIsEmpty(param: any): boolean {
    if (typeof param == "string" && param != "") {
      return false;
    }

    return true;
  }

  checkUserIsAuthenticate(): boolean {
    // Check SSO
    const token = useAuth.getToken();
    const refreshToken = useAuth.getRefreshToken();
    const expiresAt = useAuth.getExpiresAt();

    if (
      typeof token == "string" &&
      typeof refreshToken == "string" &&
      typeof expiresAt == "string"
    ) {
      if (token != "" && refreshToken != "" && expiresAt != "") {
        return true;
      }
    }

    return false;
  }

  getUrlParams(): any {
    const search = location.search.replace("?", "");
    let params = {};

    for (const param of search.split("&")) {
      const paramKey = param.split("=")[0];
      const paramValue = param.split("=")[1];

      params = {
        ...params,
        [paramKey]: paramValue,
      };
    }

    return params;
  }
}

const appSSO = new AppSSO();
export default appSSO;
