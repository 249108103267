import AuthService from "@core/services/auth/authService";
import defaultConfig from "./brandsDefaultConfig";
import { AxiosInstance } from "axios";
import {
  TBrandConfig,
  IBrandService,
  IBrandCreateResponse,
  IBrandDeleteResponse,
  IResponseGetBrandById,
  IResponseListBrands,
  IBodyListBrand,
  IBrandUpdateResponse,
  IBrandWrite,
} from "@core/services/interfaces/vehicle/brands/IBrandService";

export default class BrandService extends AuthService implements IBrandService {
  brandServiceConfig: TBrandConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.brandServiceConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Retorna uma marca pelo ID
   */
  requestGetById(brandId: number): Promise<IResponseGetBrandById> {
    return this.axiosIns.get(
      this.brandServiceConfig.getBrandByIdEndpoint + "?id=" + brandId
    );
  }

  /**
   * Retorna uma lista de marcas
   */
  requestList(args: IBodyListBrand): Promise<IResponseListBrands> {
    return this.axiosIns.post(
      this.brandServiceConfig.listBrandsEndpoint +
        `?paginar=${args.paginar ? args.paginar : "false"}`,
      args
    );
  }

  /**
   * Cria uma marca
   */
  requestCreate(brand: IBrandWrite): Promise<IBrandCreateResponse> {
    return this.axiosIns.post(
      this.brandServiceConfig.createBrandEndpoint,
      brand
    );
  }

  /**
   * Atualiza uma marca
   */
  requestUpdate(brand: IBrandWrite): Promise<IBrandUpdateResponse> {
    return this.axiosIns.put(
      this.brandServiceConfig.updateBrandEndpoint,
      brand
    );
  }

  /**
   * Apaga uma marca
   */
  requestDelete(brandId: number): Promise<IBrandDeleteResponse> {
    return this.axiosIns.delete(
      this.brandServiceConfig.deleteBrandEndpoint + "?id=" + brandId
    );
  }
}
