import useArchive from "@core/services/geral/archive/useArchive";
import axios from "axios";
import defaultConfig from "../../defaultConfig";

const axiosIns = axios.create({
  baseURL: defaultConfig.generalService,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  },
});

const { archive } = useArchive(axiosIns, {});
export default archive;
