import { Timestamp, Unsubscribe } from "firebase/firestore";

export enum EnumNotificationType {
  NotificacaoDashboard = "NotificacaoDashboard",
  NotificacaoValidacaoMarca = "NotificacaoValidacaoMarca",
  NotificacaoCreateUserFinish = "NotificacaoCreateUserFinish",
  NotificacaoImportEmplacamento = "ImportacaoEmplacamento",
}

export interface INotification {
  Id: string;
  Message: string;
  Read: boolean;
  Type: EnumNotificationType | string;
  Timestamp: Timestamp;
  HouveramEmpresasNovas?: boolean;
  FileId?: string;
  Downloading: boolean;
}

export interface IBrandValidation {
  Id: string;
  Mensagem: string;
  Status: string;
  Sucesso: boolean;
  Tipo: number;
  FileId: string;
  UsuarioId: string;
  Data: Timestamp;
  DataInicio: Timestamp;
  DataTermino: Timestamp;
  GrupoId: string;
  Downloading: boolean;
}

export interface IBilling {
  Id: string;
  Status: string;
  Data: Timestamp;
  Finalizada: boolean;
  GrupoId: string;
}

export interface IFirebaseState {
  notifications: INotification[];
  userProcessing: string[];
  brandValidations: IBrandValidation[];
  billings: IBilling[];
  unsubscribe: Unsubscribe[];
  unsubscribeBrandValidations: Unsubscribe[];
  unsubscribeBillings: Unsubscribe[];
}

let firebaseState: IFirebaseState = {
  notifications: [],
  userProcessing: [],
  brandValidations: [],
  billings: [],
  unsubscribe: [],
  unsubscribeBrandValidations: [],
  unsubscribeBillings: [],
};

export default firebaseState;
