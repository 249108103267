import { IUserData } from "@core/services/interfaces/IUtil";

export default {
  currentUser: (): IUserData => {
    const userData = localStorage.getItem("userData");

    if (typeof userData == "string") {
      if (userData != "")
        return JSON.parse(localStorage.getItem("userData") || "null");
    }

    return {} as IUserData;
  },
};
