import AuthService from "@core/services/auth/authService";
import defaultConfig from "./stateDefault";
import { AxiosInstance } from "axios";
import {
  IStateService,
  IStateConfig,
  IResponseListState,
  IResponseGetCitiesByStateId,
} from "@core/services/interfaces/covarege-area/state/IStateService";
import { IBodyListState } from "@core/services/interfaces/covarege-area/state/IBodySearch";

export default class StateService extends AuthService implements IStateService {
  serviceStateConfig: IStateConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceStateConfig = { ...defaultConfig, ...overrideConfig };
  }

  requestGetCityByStateId(
    stateId: string
  ): Promise<IResponseGetCitiesByStateId> {
    return this.axiosIns.post(
      this.serviceStateConfig.getCitiesByStateIdEndpoint +
        "?estadoId=" +
        stateId +
        "&paginar=false",
      {
        start: 0,
      }
    );
  }

  /**
   * Retorna uma lista de estados
   */
  requestList(body: IBodyListState): Promise<IResponseListState> {
    const enablePagination = body.paginar ? "?paginar=true" : "";
    return this.axiosIns.post(
      this.serviceStateConfig.listStateEndpoint + enablePagination,
      body
    );
  }
}
