import { Unsubscribe } from "firebase/firestore";
import {
  IBilling,
  IBrandValidation,
  IFirebaseState,
  INotification,
} from "./firebaseState";

export default {
  SET_UNSUBSCRIBE(state: IFirebaseState, unsubscriber: Unsubscribe[]) {
    state.unsubscribe = unsubscriber;
  },
  SET_UNSUBSCRIBE_BRAND_VALIDATIONS(
    state: IFirebaseState,
    unsubscriber: Unsubscribe[]
  ) {
    state.unsubscribeBrandValidations = unsubscriber;
  },
  SET_NOTIFICATIONS(state: IFirebaseState, notifications: INotification[]) {
    state.notifications = notifications.sort(
      (a, b) => b.Timestamp.toMillis() - a.Timestamp.toMillis()
    );
  },
  SET_USER_PROCESSING(state: IFirebaseState, userProcessing: string[]) {
    state.userProcessing = userProcessing;
  },
  SET_BRAND_VALIDATIONS(
    state: IFirebaseState,
    brandValidations: IBrandValidation[]
  ) {
    state.brandValidations = brandValidations.sort(
      (a, b) => b.Data.toMillis() - a.Data.toMillis()
    );
  },
  SET_BILLINGS(state: IFirebaseState, billings: IBilling[]) {
    state.billings = billings.sort(
      (a, b) => b.Data.toMillis() - a.Data.toMillis()
    );
  },
};
