export interface IRecoverPassword {
  email: string;
  code: string;
}
export interface IUserState {
  recoverPassword: IRecoverPassword;
}

let userState: IUserState = {
  recoverPassword: {
    email: "",
    code: "",
  },
};

export default userState;
