import { AxiosInstance } from "axios";
import GeralImportService from "./geralImportService";

export default function useGeralImport(
  axiosIns: AxiosInstance,
  overrideConfig: Object
) {
  const geralImport = new GeralImportService(axiosIns, overrideConfig);

  return {
    geralImport,
  };
}
