export interface IAuthenticationState {}

let authenticationState: IAuthenticationState = {};

export default authenticationState;

export interface IAuthInfo {
  UserName: string;
  Password: string;
  Domain: string;
}
