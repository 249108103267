import useSegment from "@core/services/vehicle/segment/useSegment";
import axios from "axios";
import defaultConfig from "../../defaultConfig";

// defaultConfig.authenticationService
const axiosIns = axios.create({
  baseURL: defaultConfig.vehicleService,
});

const { segment } = useSegment(axiosIns, {});
export default segment;
