export interface IAreaState {
  area: IArea[]
}

let areaState: IAreaState = {
  area: []
};

export default areaState;

export interface IArea {
  id?: number,
  name: string,
  codigo: number,
  regiaoOperacionalId: number,
  regiaoOperacionalNome: string,
  author: string,
}