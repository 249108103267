import authDefaultConfig from "./authDefaultConfig";
import { AxiosInstance, AxiosResponse } from "axios";
import {
  IAuthService,
  IAuthConfig,
  IBodyLogin,
  IResponseAuth,
} from "@core/services/interfaces/auth/IAuthService";
import defaultConfig from "@/services/defaultConfig";
import { IUserData } from "../interfaces/IUtil";

export default class AuthService implements IAuthService {
  // Base URL Authentication
  baseUrlAuth: string = defaultConfig.authenticationService;

  // Will be used by this service for making API calls
  axiosIns: AxiosInstance;

  // serviceConfig <= Will be used by this service
  serviceConfig: IAuthConfig;

  // For Refreshing Token
  isAlreadyFetchingAccessToken: boolean;

  // For Refreshing Token
  subscribers: Array<Function>;
  offSubscribers: Array<Function>;

  constructor(axiosIns: AxiosInstance, authOverrideConfig: Object) {
    this.axiosIns = axiosIns;
    this.serviceConfig = { ...authDefaultConfig, ...authOverrideConfig };
    this.isAlreadyFetchingAccessToken = false;
    this.subscribers = [];
    this.offSubscribers = [];

    this.checkLogoutCookie();
    this.configureInterceptorsAxiosInstance(axiosIns);
  }

  private checkLogoutCookie() {
    let checkCookie = () => {
      let stringCacheName = "logout";

      let cacheLogout = this.readCookie(stringCacheName);

      if (cacheLogout == "1") {
        this.eraseCookie(stringCacheName);
        this.logout(
          window.location.pathname,
          process.env.VUE_APP_LOGOUT_URL ? process.env.VUE_APP_LOGOUT_URL : ""
        ).catch(() => {
          console.log("Erro no logout.");
        });
      }
    };
    window.setInterval(checkCookie, 1000); // run every 1 s
  }

  private configureInterceptorsAxiosInstance(axiosIns: AxiosInstance) {
    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.serviceConfig.tokenType} ${accessToken}`;
        }
        config.headers.Domain = this.getDomain();

        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;

            this.refreshToken()
              .then((r: AxiosResponse<IResponseAuth>) => {
                this.isAlreadyFetchingAccessToken = false;
                // Update accessToken in localStorage
                this.setToken(r.data.token);
                this.setRefreshToken(r.data.refreshToken);
                this.setExpiresAt(r.data.expiresAt);

                this.onAccessTokenFetched(r.data.token);
              })
              .catch(() => {
                this.offAccessTokenFetched();

                localStorage.setItem(
                  this.serviceConfig.storageNotificarLogout,
                  "true"
                );

                this.logout(
                  window.location.pathname,
                  process.env.VUE_APP_LOGOUT_URL
                    ? process.env.VUE_APP_LOGOUT_URL
                    : ""
                ).catch(() => {
                  console.log("Erro no logout.");
                });
              });
          }

          const retryOriginalRequest = new Promise((resolve, reject) => {
            this.addSubscriber((accessToken: string) => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.serviceConfig.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });

            this.addOffSubscriber(() => {
              reject(error);
            });
          });

          return retryOriginalRequest;
        }

        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken: string): void {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  offAccessTokenFetched(): void {
    this.offSubscribers = this.offSubscribers.filter((callback) => callback());
  }

  addSubscriber(callback: Function): void {
    this.subscribers.push(callback);
  }

  addOffSubscriber(callback: Function): void {
    this.offSubscribers.push(callback);
  }

  createCookie(name: string, value: string, days: number) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toString();
    } else {
      var expires = "";
    }

    document.cookie = `${name}=${value + expires};path=/; domain=${
      process.env.VUE_APP_DOMAIN
    }`;
  }

  readCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }

    return null;
  }

  eraseCookie(name: string) {
    this.createCookie(name, "", -1);
  }

  getToken(): string | null {
    let token = this.readCookie(
      this.serviceConfig.storageTokenKeyFirstPartName
    );

    if (token) {
      token +=
        "" + this.readCookie(this.serviceConfig.storageTokenKeySecondPartName);
    }

    return token;
  }

  getRefreshToken(): string | null {
    return this.readCookie(this.serviceConfig.storageRefreshTokenKeyName);
  }

  getExpiresAt(): string | null {
    return this.readCookie(this.serviceConfig.storageExpiresAtKeyName);
  }

  getDomain(): string | null {
    return localStorage.getItem(this.serviceConfig.storageDomainKeyName);
  }

  getCurrentUser(): IUserData | null {
    const currentUser: IUserData | null = JSON.parse(
      localStorage.getItem("userData") || "null"
    );

    return currentUser;
  }

  setToken(token: string): void {
    const middle = Math.floor(token.length / 2);

    const apiToken1 = token.substring(0, middle);
    const apiToken2 = token.substring(middle);
    this.createCookie(
      this.serviceConfig.storageTokenKeyFirstPartName,
      apiToken1,
      1
    );
    this.createCookie(
      this.serviceConfig.storageTokenKeySecondPartName,
      apiToken2,
      1
    );
  }

  setRefreshToken(value: string): void {
    this.createCookie(this.serviceConfig.storageRefreshTokenKeyName, value, 1);
  }

  setExpiresAt(value: string): void {
    this.createCookie(this.serviceConfig.storageExpiresAtKeyName, value, 1);
  }

  setDomain(value: string): void {
    localStorage.setItem(this.serviceConfig.storageDomainKeyName, value);
  }

  purgeCache(): void {
    localStorage.removeItem(this.serviceConfig.storageUserDataName);
    localStorage.removeItem(this.serviceConfig.storageParamsName);
    this.eraseCookie(this.serviceConfig.storageTokenKeyFirstPartName);
    this.eraseCookie(this.serviceConfig.storageTokenKeySecondPartName);
    this.eraseCookie(this.serviceConfig.storageExpiresAtKeyName);
    this.eraseCookie(this.serviceConfig.storageRefreshTokenKeyName);

    localStorage.setItem(
      this.serviceConfig.storagePurgeCacheName,
      this.serviceConfig.storagePurgeCacheCleanValue
    );

    console.log("Cache Clean");
  }

  login(args: IBodyLogin): Promise<AxiosResponse<IResponseAuth>> {
    return this.axiosIns.post(
      this.baseUrlAuth + this.serviceConfig.loginEndpoint,
      args
    );
  }

  changeToken(
    system: "emplacamento" | "EmplacamentoPortal"
  ): Promise<AxiosResponse<IResponseAuth>> {
    return this.axiosIns.get(
      this.baseUrlAuth +
        this.serviceConfig.changeTokenEndpoint +
        `?sistema=${system}`
    );
  }

  async logout(redirectUrl = "", logoutUrl = ""): Promise<void> {
    let url =
      logoutUrl.trim() != "" ? logoutUrl : window.location.origin + "/login";

    if (redirectUrl != "") {
      url += "?redirectTo=" + redirectUrl;
    }

    // Remove userData from cookies
    this.eraseCookie(this.serviceConfig.storageExpiresAtKeyName);
    this.eraseCookie(this.serviceConfig.storageRefreshTokenKeyName);
    this.eraseCookie(this.serviceConfig.storageTokenKeyFirstPartName);
    this.eraseCookie(this.serviceConfig.storageTokenKeySecondPartName);

    // Remove userData from localStorage
    localStorage.removeItem(this.serviceConfig.storageUserDataName);

    // Redirect to login page
    window.location.href = url;
  }

  refreshToken(): Promise<AxiosResponse<IResponseAuth>> {
    return this.axiosIns.post(
      this.baseUrlAuth + this.serviceConfig.refreshEndpoint,
      {
        token: this.getToken(),
        expiresAt: this.getExpiresAt(),
        refreshToken: this.getRefreshToken(),
      }
    );
  }
}
