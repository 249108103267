import events from "@/resources/systems-modules-events/events.json";
import store from "@/store";

const groupTitle = store.state["appConfig"].params.GRUPO_EMPRESA_TITLE;
const hasEconomicGroup =
  store.state["appConfig"].params.EMP_ASSOCIACAO_GRUPO_ECONOMICO == "S";
const resourceEconomicGroup = hasEconomicGroup
  ? events.Emp_Grupos_Economicos.id
  : "all";

export default [
  // *===============================================-------------*
  // *-------------------Área Operacional-------------------------*
  // *===============================================-------------*
  {
    path: "/admin-panel/area/list",
    name: "admin-panel-area-list",
    meta: {
      action: "manage",
      resource: events.Emp_Municipios.id,
      pageTitle: "Área Operacional",
    },
    component: () => import("@/views/admin-panel/area/area-list/AreaList.vue"),
  },
  {
    path: "/admin-panel/area/add",
    name: "admin-panel-area-add",
    meta: {
      action: "manage",
      resource: events.Emp_Municipios.id,
      pageTitle: "Área Operacional",
      breadcrumb: [
        {
          to: { name: "admin-panel-area-list" },
          text: "Área Operacional",
        },
        {
          text: "Adicionar",
          active: true,
        },
      ],
    },
    component: () => import("@/views/admin-panel/area/area-add/AreaAdd.vue"),
  },
  {
    path: "/admin-panel/area/edit/:id",
    name: "admin-panel-area-edit",
    meta: {
      action: "manage",
      resource: events.Emp_Municipios.id,
      pageTitle: "Área Operacional",
      breadcrumb: [
        {
          to: { name: "admin-panel-area-list" },
          text: "Área Operacional",
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
    component: () => import("@/views/admin-panel/area/area-edit/AreaEdit.vue"),
  },
  // *===============================================---*
  // *--------------------Regiões-----------------------*
  // *===============================================---*
  {
    path: "/admin-panel/regions/list",
    name: "admin-panel-regions-list",
    meta: {
      action: "manage",
      resource: events.Emp_Municipios.id,
      pageTitle: "Regiões",
    },
    component: () =>
      import("@/views/admin-panel/regions/regions-list/RegionList.vue"),
  },
  // *===============================================---*
  // *------------------Municípios----------------------*
  // *===============================================---*
  {
    path: "/admin-panel/county/list",
    name: "admin-panel-county-list",
    meta: {
      action: "manage",
      resource: events.Emp_Municipios.id,
      pageTitle: "Municípios",
    },
    component: () =>
      import("@/views/admin-panel/county/city-list/CityList.vue"),
  },
  // *===============================================---*
  // *------------------Grupo Empresa----------------*
  // *===============================================---*
  {
    path: "/admin-panel/group/list",
    name: "admin-panel-group-list",
    meta: {
      action: "manage",
      resource: events.Emp_Grupos.id,
      pageTitle: groupTitle,
    },
    component: () =>
      import("@/views/admin-panel/group/groups-list/GroupsList.vue"),
  },
  // *===============================================---*
  // *------------------Grupo Econômico-----------------*
  // *===============================================---*
  {
    path: "/admin-panel/group-economic/list",
    name: "admin-panel-group-economic-list",
    meta: {
      action: "manage",
      resource: resourceEconomicGroup,
      pageTitle: "Grupo Econômico",
    },
    component: () =>
      import(
        "@/views/admin-panel/group-economic/groups-economic-list/GroupsEconomicList.vue"
      ),
  },
  // *===============================================---*
  // *------------------Concessionária----------------*
  // *===============================================---*
  {
    path: "/admin-panel/dealerships/list",
    name: "admin-panel-dealerships-list",
    meta: {
      action: "manage",
      resource: events.Emp_Empresas.id,
      pageTitle: "Empresas",
      breadcrumb: [
        {
          to: { name: "admin-panel-dealerships-list" },
          text: "Empresas",
          active: true,
        },
      ],
    },
    component: () =>
      import(
        "@/views/admin-panel/dealerships/dealerships-list/DealershipsList.vue"
      ),
  },
  {
    path: "/admin-panel/dealerships/add",
    name: "admin-panel-dealerships-add",
    meta: {
      action: "manage",
      resource: events.Emp_Empresas.id,
      pageTitle: "Empresas",
      breadcrumb: [
        {
          to: { name: "admin-panel-dealerships-list" },
          text: "Empresas",
        },
        {
          text: "Adicionar",
          active: true,
        },
      ],
    },
    component: () =>
      import(
        "@/views/admin-panel/dealerships/dealerships-add-edit/DealershipsAddEdit.vue"
      ),
  },
  {
    path: "/admin-panel/dealerships/edit/:id",
    name: "admin-panel-dealerships-edit",
    meta: {
      action: "manage",
      resource: events.Emp_Empresas.id,
      pageTitle: "Empresas",
      breadcrumb: [
        {
          to: { name: "admin-panel-dealerships-list" },
          text: "Empresas",
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
    component: () =>
      import(
        "@/views/admin-panel/dealerships/dealerships-add-edit/DealershipsAddEdit.vue"
      ),
  },
  // *===============================================---*
  // *-------------------Usuários-----------------------*
  // *===============================================---*
  {
    path: "/admin-panel/users",
    name: "admin-panel-users-list",
    meta: {
      action: "manage",
      resource: events.Emp_Usuarios.id,
      pageTitle: "Usuários",
    },
    component: () =>
      import("@/views/admin-panel/user/users-list/UsersList.vue"),
  },
  {
    path: "/admin-panel/user/add",
    name: "admin-panel-users-add",
    meta: {
      action: "manage",
      resource: events.Emp_Usuarios.id,
      pageTitle: "Usuários",
      breadcrumb: [
        {
          to: { name: "admin-panel-users-list" },
          text: "Usuários",
        },
        {
          text: "Adicionar",
          active: true,
        },
      ],
    },
    component: () => import("@/views/admin-panel/user/users-add/UsersAdd.vue"),
  },
  {
    path: "/admin-panel/user/edit/:id",
    name: "admin-panel-users-edit",
    meta: {
      action: "manage",
      resource: events.Emp_Usuarios.id,
      pageTitle: "Usuários",
      breadcrumb: [
        {
          to: { name: "admin-panel-users-list" },
          text: "Usuários",
        },
        {
          text: "Editar",
          active: true,
        },
      ],
    },
    component: () =>
      import("@/views/admin-panel/user/users-edit/UsersEdit.vue"),
  },
  {
    path: "/admin-panel/pending-users",
    name: "admin-panel-pending-users",
    meta: {
      action: "manage",
      resource: events.Emp_Usuários_Pendentes.id,
      pageTitle: "Usuários Pendentes",
      breadcrumb: [],
    },
    component: () =>
      import("@/views/admin-panel/user/pending-users/PendingUsers.vue"),
  },
  // *===============================================---*
  // *--------------------Perfil-----------------------*
  // *===============================================---*
  {
    path: "/admin-panel/profile-settings/page",
    name: "admin-panel-profile-page",
    meta: {
      action: "read",
      resource: "Auth",
      // action: 'manage',
      // TODO: resource: events.Emp_MeuCadastro.id,
    },
    component: () => import("@/views/admin-panel/profile/ProfileUser.vue"),
  },
  // *===============================================---*
  // *----------------Validação da marca----------------*
  // *===============================================---*
  {
    path: "/admin-panel/brand-validation",
    name: "admin-panel-brand-validation",
    meta: {
      action: "manage",
      resource: events.Emp_Validacao_da_marca.id,
      pageTitle: "Validação da Marca",
      breadcrumb: [],
    },
    component: () => import("@/views/admin-panel/validation/ValidateBrand.vue"),
  },
  // *===============================================---*
  // *-------------------Nomenclatura-------------------*
  // *===============================================---*
  {
    path: "/admin-panel/vehicle-version",
    name: "admin-panel-vehicle-version",
    meta: {
      pageTitle: "Nomenclatura",
      resource: events.Emp_Nomenclaturas.id,
      embedUrl: "/Emplacamento/Nomenclatura",
    },
    component: () =>
      import("@/views/admin-panel/vehicle-version/Nomenclature.vue"),
  },
  // *===============================================---*
  // *---------------Importar Emplacamento--------------*
  // *===============================================---*
  {
    path: "/admin-panel/import-emplacamento",
    name: "admin-panel-import-emplacamento",
    meta: {
      action: "manage",
      resource: events.Emp_Importar_Emplacamentos.id,
      pageTitle: "Importar Emplacamento",
    },
    component: () =>
      import(
        "@/views/admin-panel/import-emplacamento/ImportEmplacamentoList.vue"
      ),
  },
  // *===============================================---*
  // *---------------Importar Faturamento--------------*
  // *===============================================---*
  {
    path: "/admin-panel/import-billing",
    name: "admin-panel-import-billing",
    meta: {
      action: "manage",
      resource: events.Emp_Importar_Faturamentos.id,
      pageTitle: "Importar Faturamento",
    },
    component: () =>
      import(
        "@/views/admin-panel/import-faturamento/ImportFaturamentoList.vue"
      ),
  },
  // *===============================================---*
  // *---------------Efetivar Faturamento---------------*
  // *===============================================---*
  {
    path: "/admin-panel/effectivate-billing",
    name: "admin-panel-effectivate-billing",
    meta: {
      action: "manage",
      resource: events.Emp_Efetivar_Faturamentos.id,
      pageTitle: "Efetivar Faturamento",
    },
    component: () =>
      import("@/views/admin-panel/effectivate-billing/EffectivateBilling.vue"),
  },
];
