import actions from "./librariesActions";
import getters from "./librariesGetters";
import state from "./librariesState";
import mutations from "./librariesMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};