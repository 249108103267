import { AxiosInstance } from "axios";
import ArchiveService from "./archiveService";

export default function useArchive(
  axiosIns: AxiosInstance,
  overrideConfig: Object
) {
  const archive = new ArchiveService(axiosIns, overrideConfig);

  return {
    archive,
  };
}
