import { AxiosInstance } from "axios";
import PersonService from "./personService";

export default function usePerson(
  axiosIns: AxiosInstance,
  overrideConfig: Object
) {
  const person = new PersonService(axiosIns, overrideConfig);

  return {
    person,
  };
}
