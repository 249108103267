import AuthService from "@core/services/auth/authService";
import defaultConfig from "./nomenclatureDefaultConfig";
import { AxiosInstance } from "axios";
import {
  IResponseGetCategory,
  IBodyList,
  INomenclatureService,
  INomenclatureUpdate,
  IResponseListNomenclature,
  IResponseUpdateNomenclature,
  INomenclatureConfig,
} from "../../interfaces/vehicle/nomenclature/INomenclatureService";

export default class NomenclatureService
  extends AuthService
  implements INomenclatureService
{
  nomenclatureServiceConfig: INomenclatureConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.nomenclatureServiceConfig = { ...defaultConfig, ...overrideConfig };
  }

  requestList(parameters: IBodyList): Promise<IResponseListNomenclature> {
    return this.axiosIns.post(
      this.nomenclatureServiceConfig.listNomenclatureEndpoint +
        "?paginar=" +
        (parameters.paginate ? parameters.paginate : true),
      parameters
    );
  }

  /**
   * Atualiza um modelo
   */
  requestUpdate(
    nomenclature: INomenclatureUpdate
  ): Promise<IResponseUpdateNomenclature> {
    return this.axiosIns.put(
      this.nomenclatureServiceConfig.updateSingleModelEndpoint,
      nomenclature
    );
  }

  requestGet(): Promise<IResponseGetCategory> {
    return this.axiosIns.get(
      this.nomenclatureServiceConfig.getCategoriesEndpoint
    );
  }
}
