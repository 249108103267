import useRegion from "@core/services/covarege-area/region/useRegion";
import axios from "axios";
import defaultConfig from "../../defaultConfig";

const axiosIns = axios.create({
  baseURL: defaultConfig.coverageAreaService,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  },
});

const { region } = useRegion(axiosIns, {});
export default region;
