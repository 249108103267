import actions from "./positionsActions";
import getters from "./positionsGetters";
import state from "./positionsState";
import mutations from "./positionsMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};