export interface IAppState {
  windowWidth     : number,
  shallShowOverlay: boolean,
}

let appState: IAppState = {
  windowWidth     : 0,
  shallShowOverlay: false,
};

export default appState;