import { AxiosInstance } from "axios";
import AreaService from "./areaService";

export default function useBrandValidation(
  axiosIns: AxiosInstance,
  areaOverrideConfig: Object
) {
  const area = new AreaService(axiosIns, areaOverrideConfig);

  return {
    area,
  };
}
