import actions from "./partnersActions";
import getters from "./partnersGetters";
import state from "./partnersState";
import mutations from "./partnersMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};