import actions from "./cityActions";
import getters from "./cityGetters";
import state from "./cityState";
import mutations from "./cityMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};