import actions from "./regionsActions";
import getters from "./regionsGetters";
import state from "./regionsState";
import mutations from "./regionsMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};