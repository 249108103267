import { Commit } from "vuex";
import { useGroupEconomic } from "@/services";
import {
  IGroupEconomic,
  IGroupEconomicCreateResponse,
  IGroupEconomicUpdateResponse,
  IGroupEconomicDeleteResponse,
} from "@core/services/interfaces/business/group-economic/IGroupEconomicService";

export default {
  /**
   * Solicita a criação de um grupo econômico
   */
  fetchCreateGroupEconomic(
    { commit }: { commit: Commit },
    groupEconomic: IGroupEconomic
  ): Promise<IGroupEconomicCreateResponse> {
    return new Promise((resolve, reject) => {
      useGroupEconomic
        .requestCreate(groupEconomic)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Solicita a atualização de um grupo econômico
   */
  fetchUpdateGroupEconomic(
    { commit }: { commit: Commit },
    groupEconomic: IGroupEconomic
  ): Promise<IGroupEconomicUpdateResponse> {
    return new Promise((resolve, reject) => {
      useGroupEconomic
        .requestUpdate(groupEconomic)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Solicita a exclusão de um grupo econômico
   */
  fetchDeleteGroupsEconomic(
    { commit }: { commit: Commit },
    groupEconomicId: number
  ): Promise<IGroupEconomicDeleteResponse> {
    return new Promise((resolve, reject) => {
      useGroupEconomic
        .requestDelete(groupEconomicId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
