import events from "@/resources/systems-modules-events/events.json";

export default [
  // *===============================================---*
  // *-------------------Configurações--------------------*
  // *===============================================---*
  // TODO: Event - Precisamos de um evento para a página de configurações
  {
    path: '/administrative/settings',
    name: 'administrative-settings-list',
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application todo-application',
      action: 'manage',
      resource: events.Emp_Perfis.id,
    },
    component: () => import( '@/views/administrative/settings/SettingsList.vue' ),
  },
  {
    path: '/administrative/settings/tag/:tag',
    name: 'administrative-settings-event-tag',
    component: () => import( '@/views/administrative/settings/SettingsList.vue' ),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application todo-application',
      navActiveLink: 'administrative-settings-list',
      action: 'manage',
      resource: events.Emp_Perfis.id,
    },
    beforeEnter( to, _, next ) {
      if( [ 'Ativo', 'Inativo' ].includes( to.params.tag ) ) next();
      else next( { name: 'error-404' } );
    },
  },
]