import { TResponseDistricts } from "./districtsState";
import { Commit } from "vuex/types/index";

export default {
  fetchDistricts( { commit }: { commit: Commit } ) : Promise<TResponseDistricts> {
    const dataFake: TResponseDistricts = {
      data: [
        {
          Id    : 1,
          name  : "AAAA",
          code  : "A1B1",
          status: true 
        },
        {
          Id    : 2,
          name  : "BBBB",
          code  : "A1B2",
          status: true
        },
        {
          Id    : 3,
          name  : "CCCC",
          code  : "A1B3",
          status: true
        },
        {
          Id    : 4,
          name  : "DDDD",
          code  : "A1B5",
          status: false
        },
        {
          Id    : 6,
          name  : "EEEE",
          code  : "A1B6",
          status: true
        },
        {
          Id    : 7,
          name  : "FFFF",
          code  : "A1B7",
          status: true
        },
        {
          Id    : 8,
          name  : "GGGG",
          code  : 'A1B8',
          status: false
        },
      ],
      draw            : 1,
      recordsTotal    : 8,
      recordsFiltered : 8,
      lastPage        : 1
    };

    return new Promise( ( resolve, reject ) => {
      resolve( dataFake );
    } )
  },
};