import useCalendar from "@core/services/geralEmplacamento/calendar/useCalendar";
import axios from "axios";
import defaultConfig from "../../defaultConfig";

const axiosIns = axios.create({
  baseURL: defaultConfig.generalEmplacamentoService,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  },
});

const { calendar } = useCalendar(axiosIns, {});
export default calendar;
