import { TDealershipsConfig } from "@core/services/interfaces/business/dealerships/IDealershipsService";

const userConfig: TDealershipsConfig = {
  // Endpoints
  getDealershipByIdEndpoint:
    "/api/GerenciarEmpresas/EmpresaGrid/ObterEmpresaPorId",
  getFiltersDealershipsIdEndpoint:
    "/api/GerenciarEmpresas/EmpresaGrid/ObterFiltros",
  getListDealershipsEndpoint:
    "/api/GerenciarEmpresas/EmpresaGrid/ListarEmpresasDetalhadas",
  updateDealershipEndpoint:
    "/api/GerenciarEmpresas/EmpresaAssociada/AtualizarEmpresa",
  createDealershipEndpoint:
    "/api/GerenciarEmpresas/EmpresaAssociada/CadastrarEmpresa",
};

export default userConfig;
