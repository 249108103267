import { Commit } from "vuex";
import { useParam } from "@/services";
import {
  IParam,
  IResponseGetParameterValue,
} from "@core/services/interfaces/geral/param/IParamService";
import { params as parameters } from "@/resources/params";

const $PARAMS: any = parameters;

async function getParam(paramId: string): Promise<IParam[]> {
  return await useParam
    .requestGetParameterValue(paramId)
    .then((response: IResponseGetParameterValue) => response.data.data)
    .catch(() => []);
}

async function setSingleParam(key: string, params: any) {
  if (typeof key == "string") {
    const param = $PARAMS[key];
    if (param) {
      await getParam(param.id).then((response: IParam[]) => {
        if (response.length > 0) {
          params[key] = response[0].valor;
        }
      });
    }
  }
}

export default {
  /**
   * Solicita ao serviço uma area operacional/influencia pelo ID
   */
  async fetchLoadSystemParameters({
    commit,
  }: {
    commit: Commit;
  }): Promise<void> {
    let params = JSON.parse(localStorage.getItem("params") || "null");

    params = {
      EMP_FABRICANTE: null,
      EMP_RELATORIOS_COR: null,
      ABRACAF_CODIGO_PERFIL_GRUPO: null,
      ABRACAF_CODIGO_PERFIL_ADMIN: null,
      ABRACAF_CODIGO_PERFIL_LOJA: null,
      ABRACAF_CODIGO_PERFIL_REGIONAL: null,
      EMP_ASSOCIACAO_GRUPO_ECONOMICO: null,
      ADMINISTRACAO_CADASTRO_PESSOA_FISICA_DOCUMENTO_OBRIGATORIO: null, // Toggle obrigar campo CPF em cadastro de usuário.
    };

    let parallelProcesses: Array<Promise<void>> = [];

    Object.entries(params).forEach(([key, value]) => {
      parallelProcesses.push(setSingleParam(key, params));
    });

    await Promise.all(parallelProcesses);

    if (params.EMP_ASSOCIACAO_GRUPO_ECONOMICO == "N") {
      params.GRUPO_EMPRESA_TITLE = "Grupo Econômico";
    } else {
      params.GRUPO_EMPRESA_TITLE = "Grupo Empresa";
    }

    commit("SET_PARAMS", params);
    localStorage.setItem("params", JSON.stringify(params));
  },
};
