function _getAllWords(value) {
  return value.split(" ").join("");
}

function chassiIsValid(chassi) {
  if (!chassi) return true;

  let regexTemCaracterEspecial = /[^a-zA-Z0-9 ]/;
  if (regexTemCaracterEspecial.test(chassi)) {
    return false;
  }

  // 1 - Possuir o número "0" (ZERO) como 1º dígito.
  var zeroNoPrimeiroDigito = /^0/;
  if (zeroNoPrimeiroDigito.test(chassi)) {
    return false;
  }
  // 2 - Possuir espaço no chassi
  chassi = _getAllWords(chassi); // espacoNoChassi
  // 3 - Se, a partir do 4º dígito, houver uma repetição consecutiva,
  // por mais de seis vezes, do mesmo dígito
  // (alfabético ou numérico). Exemplos: 9BW11111119452687 e 9BWZZZ5268AAAAAAA.
  var repeticaoMaisDe6Vezes = /^.{4,}([0-9A-Z])\1{5,}/;
  if (repeticaoMaisDe6Vezes.test(chassi)) {
    return false;
  }
  // 4 - Apresente os caracteres "i", "I", "o", "O", "q", "Q".
  var caracteresiIoOqQ = /[iIoOqQ]/;
  if (caracteresiIoOqQ.test(chassi)) {
    return false;
  }
  // 5 - Os quatro últimos caracteres devem ser obrigatoriamente numéricos
  var ultimos4Numericos = /[0-9]{4}$/;
  if (!ultimos4Numericos.test(chassi)) {
    return false;
  }
  // 6 - Se possuir número de dígitos diferente de 17 (alfanuméricos).
  if (chassi.length !== 17) {
    return false;
  }
  return true;
}

export default chassiIsValid;
