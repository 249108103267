import actions from "./stateActions";
import getters from "./stateGetters";
import state from "./stateState";
import mutations from "./stateMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};