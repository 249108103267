import { Ability } from '@casl/ability';
import { initialAbility } from './config';
import useAuth from '@/services/auth/useAuth';

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
//@ts-ignore
const userData = JSON.parse( localStorage.getItem( useAuth.serviceConfig.storageUserDataName ) );

const existingAbility = userData ? userData.ability : null;
const ability = new Ability( existingAbility || initialAbility );
export default ability;
