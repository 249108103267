import useCache from '@core/services/geralEmplacamento/cache/useCache';
import axios from 'axios';
import defaultConfig from '../../defaultConfig';

const axiosIns = axios.create( {
  baseURL: defaultConfig.generalEmplacamentoService,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  }
} );

const { cache } = useCache( axiosIns, {} );
export default cache;
