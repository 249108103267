import IAbility from "@/libs/acl/interfaces/IAbility";

export const mapEventsToAbilities = ( events: Array<string> ) : Array<IAbility> => {
  let abilities: Array<IAbility> = [
    {
      action: "read",
      subject: "Auth",
    },
  ];

  events.forEach( ( event ) => {
    abilities.push( {
      action: "manage",
      subject: event,
    } );
  } );

  return abilities;
};
