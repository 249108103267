import { TGroupEconomicConfig } from "@core/services/interfaces/business/group-economic/IGroupEconomicService";

const groupConfig: TGroupEconomicConfig = {
  // Endpoints
  getGroupEconomicByIdEndpoint:
    "api/GerenciarEmpresas/GrupoEconomico/ObterGrupoEconomicoPorId",
  getGroupEconomicEndpoint: "api/GerenciarEmpresas/GrupoEconomico",
  listGroupEconomicEndpoint: "/api/GerenciarEmpresas/GrupoEconomico",
  createGroupEconomicEndpoint: "/api/GerenciarEmpresas/GrupoEconomico",
  updateGroupEconomicEndpoint: "/api/GerenciarEmpresas/GrupoEconomico",
  deleteGroupEconomicEndpoint: "/api/GerenciarEmpresas/GrupoEconomico",
};

export default groupConfig;
