export interface ICountiesState {}

let countiesState: ICountiesState = {};

export default countiesState;

export type TResponseCounties = {
  data: Array<{
    Id      : number,
    name    : string,
    serpro  : string,
    ibge    : string,
    state   : string,
    area    : string,
    region  : string,
    capital : boolean
  }>,
  draw            : number,
  recordsTotal    : number,
  recordsFiltered : number,
  lastPage        : number
}