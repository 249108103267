import { Commit } from "vuex";
import { useRegion } from "@/services";
import {
  IRegion,
  IResponseGetRegionActive,
  IResponseGetRegion,
  IResponseCityRegion,
  IResponseRegionScope,
  IResponseCreateRegion,
  IResponseUpdateRegion,
} from "@core/services/interfaces/covarege-area/region/IRegionService";

export default {
  /**
   * Solicita ao serviço uma região operacional pelo ID
   */
  fetchGetRegionActives({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseGetRegionActive> {
    return new Promise((resolve, reject) => {
      useRegion
        .requestGetActives()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Solicita ao serviço uma região operacional pelo ID
   */
  fetchGetRegion(
    { commit }: { commit: Commit },
    regionId: number
  ): Promise<IResponseGetRegion> {
    return new Promise((resolve, reject) => {
      useRegion
        .requestGet(regionId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Solicita a criação de um região operacional
   */
  fetchCreateRegion(
    { commit }: { commit: Commit },
    region: IRegion
  ): Promise<IResponseCreateRegion> {
    return new Promise((resolve, reject) => {
      useRegion
        .requestCreate(region)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Solicita a atualização de uma região operacional
   */
  fetchUpdateRegion(
    { commit }: { commit: Commit },
    region: IRegion
  ): Promise<IResponseUpdateRegion> {
    return new Promise((resolve, reject) => {
      useRegion
        .requestUpdate(region)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Solicita as cidades de uma região operacional
   */
  fetchGetCityRegion({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseCityRegion> {
    return new Promise((resolve, reject) => {
      useRegion
        .requestGetCityRegion()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Solicita as cidades de uma região operacional
   */
  fetchGetRegionsScope({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseRegionScope> {
    return new Promise((resolve, reject) => {
      useRegion
        .requestGetRegionScope()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
