import useBrand from "@/@core/services/vehicle/brands/useBrand";
import axios from "axios";
import defaultConfig from "../../defaultConfig";

// defaultConfig.authenticationService
const axiosIns = axios.create({
  baseURL: defaultConfig.vehicleService,
});

const { brand } = useBrand(axiosIns, {});
export default brand;
