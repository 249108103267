import actions from "./regionAction";
import getters from "./regionGetters";
import state from "./regionState";
import mutations from "./regionMutation";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};