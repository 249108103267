import actions from "./firebaseActions";
import getters from "./firebaseGetters";
import state from "./firebaseState";
import mutations from "./firebaseMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};