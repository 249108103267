import { useSegment } from "@/services";
import { IBodyListSegment } from "@core/services/interfaces/vehicle/segment/IBodySearch";
import { Commit } from "vuex/types/index";
import {
  IResponseListSegment,
  IResponseGetSegment,
  ISegmentCreateResponse,
  ISegmentDeleteResponse,
  ISegmentUpdateResponse,
  IResponseGetSegmentTypes,
  ISegmentUpdate,
  ISegmentCreate,
} from "@core/services/interfaces/vehicle/segment/ISegmentService";

export default {
  fetchGetSegmentById(
    { commit }: { commit: any },
    segmentId: number
  ): Promise<IResponseGetSegment> {
    return new Promise((resolve, reject) => {
      useSegment
        .requestGet(segmentId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchListSegments(
    { commit }: { commit: any },
    body?: IBodyListSegment
  ): Promise<IResponseListSegment> {
    return new Promise((resolve, reject) => {
      useSegment
        .requestList(body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async fetchCreateSegment(
    { commit }: { commit: any },
    segment: ISegmentCreate
  ): Promise<ISegmentCreateResponse> {
    return new Promise((resolve, reject) => {
      useSegment
        .requestCreate(segment)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchUpdateSegment(
    { commit }: { commit: Commit },
    segment: ISegmentUpdate
  ): Promise<ISegmentUpdateResponse> {
    return new Promise((resolve, reject) => {
      useSegment
        .requestUpdate(segment)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchDeleteSegment(
    { commit }: { commit: Commit },
    segmentId: number
  ): Promise<ISegmentDeleteResponse> {
    return new Promise((resolve, reject) => {
      useSegment
        .requestDelete(segmentId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchGetSegmentTypes({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseGetSegmentTypes> {
    return new Promise((resolve, reject) => {
      useSegment
        .requestGetSegmentTypes()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
