import actions from "./brandActions";
import getters from "./brandGetters";
import state from "./brandState";
import mutations from "./brandMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};