import { Commit } from 'vuex/types/index';

export default {
  fetchMagazines( { commit }: { commit: Commit } ) {
    return new Promise( ( resolve, reject ) => {
      const dataFake = {
        data: [
          {
            id      : 1,
            title   : "Title M1",
            edition : "ED01",
            date    : "19/12/2022"
          }
        ],
        meta: {
          pagination: {
            total: 1
          }
        }
      }
      resolve( dataFake );
    } )
  },
}
