function valorMinimoDecimal(valor, valorMinimoDecimal) {
  let valorFormatado;
  if (valor) {
    valorFormatado = Number(valor.replace(/\D/g, ""));
  }
  if (valorFormatado >= Number(valorMinimoDecimal[0]) && valorFormatado !== 0) {
    return true;
  }

  return false;
}

export default valorMinimoDecimal;
