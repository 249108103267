import events from "@/resources/systems-modules-events/events.json";

export default [
  {
    path: "/dashboard-kpi",
    name: "dashboard-kpi",
    component: () => import("@/views/dashboard/kpi/Kpi.vue"),
    meta: {
      action: "manage",
      resource: events.Emp_Dashboard_Kpi.id,
    },
  },
];
