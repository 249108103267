import actions from "./districtsActions";
import getters from "./districtsGetters";
import state from "./districtsState";
import mutations from "./districtsMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};