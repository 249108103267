import actions from "./authenticationActions";
import getters from "./authenticationGetters";
import state from "./authenticationState";
import mutations from "./authenticationMutations";

export default {
  isRegistered: false,
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};