import { Commit } from 'vuex/types/index';

export default {
  fetchRegions( { commit }: { commit: Commit } ) {
    // const endpoint = '';
    const dataFake = {
      data: [
        {
          Id    : 1,
          Nome  : "REGIÃO LIVRE",
          Ativo : false,
          Autor : "marcelo.mota"
        },
        {
            Id    : 11,
            Nome  : "R1",
            Ativo : true,
            Autor : "weslan.alves"
        },
        {
            Id    : 12,
            Nome  : "R2",
            Ativo : true,
            Autor : "weslan.alves"
        },
        {
            Id    : 13,
            Nome  : "R3",
            Ativo : true,
            Autor : "giselle.ferreira"
        },
        {
            Id    : 14,
            Nome  : "R4",
            Ativo : true,
            Autor : "weslan.alves"
        }
      ],
      draw            : 1,
      recordsTotal    : 5,
      recordsFiltered : 5,
      lastPage        : 1
    };

    return new Promise( ( resolve ) => {
      resolve( dataFake );
    } )
  },
  fetchAreas( { commit }: { commit: Commit } ) {
    // const endpoint = '';
    const dataFake = {
      data: [
        {
          Id    : 1,
          Nome  : "AO 01",
          Codigo: "2111300",
          Autor : "marcelo.mota"
        },
        {
            Id    : 2,
            Nome  : "AO02",
            Codigo: "2111231",
            Autor : "weslan.alves"
        },
        {
            Id    : 3,
            Nome  : "AO03",
            Codigo: "2111352",
            Autor : "giselle.ferreira"
        },
      ],
      draw            : 1,
      recordsTotal    : 3,
      recordsFiltered : 3,
      lastPage        : 1
    };

    return new Promise( ( resolve ) => {
      resolve( dataFake );
    } )
  },
}