enum LABEL {
  CPF = "CPF",
  CNPJ = "CNPJ",
  CEP = "CEP",
  CIDADE = "Cidade",
  ESTADO = "Estado",
  BAIRRO = "Bairro",
  NUMERO = "Número",
  TELEFONE = "Telefone",
  ENPRESA = "Empresa",
  NOME = "Nome",
  DESCRICAO = "Descrição",
  NOME_FANTASIA = "Nome fantasia",
  EMAIL = "E-mail",
  LOGIN = "Login",
  LOJAS = "Lojas",
  LOJA = "Loja",
  FILIAL = "Filial",
  SISTEMA = "Sistema",
  DOMINIO = "Domínio",
  ITEM_FALTANTE = "Item faltante",
  INFORME_O_CODIGO = "Informe o código",
  TIPO_DE_OFERTA = "Tipo de oferta",
  VALOR_DE_PRECIFICACAO = "Valor de precificação",
  PRECIFICADO_POR = "Precificado por",
  SOLICITANTES = "Solicitantes",
  AVALIADORES = "Avaliadores",
  AVALIADOR = "Avaliador",
  BLINDADO = "Blindado",
  STATUS = "Status",
  DE = "De",
  ATE = "Até",
  PLACA = "Placa",
  MARCA = "Marca",
  MODELO = "Modelo",
  VERSAO = "Versão",
  ANO_FABRICACAO = "Ano fabricação",
  ANO_MODELO = "Ano modelo",
  ANO = "Ano",
  COR = "Cor",
  CORES = "Cores",
  CAMBIO = "Câmbio",
  COMBUSTIVEL = "Combustível",
  CHASSI = "Chassi",
  RENAVAM = "Renavam",
  PORTAS = "Portas",
  CATEGORIA = "Categoria",
  QUILOMETRAGEM = "Quilometragem",
  PRECIFICADORES = "Precificadores",
  TIPO_DO_VALOR = "Tipo do valor",
  TIPO_DO_PARAMETRO = "Tipo do parâmetro",
  PERFIS = "Perfis",
  STATUS_ATIVO = "Ativo",
  STATUS_INATIVO = "Inativo",
  STATUS_PROCESSANDO = "Processando",
  SIM = "Sim",
  NAO = "Não",
}

export default LABEL;
