import { AxiosInstance } from "axios";
import NomenclatureService from "./nomenclatureService";

export default function useNomenclature(
  axiosIns: AxiosInstance,
  nomenclatureOverrideConfig: Object
) {
  const nomenclature = new NomenclatureService(axiosIns, nomenclatureOverrideConfig);

  return {
    nomenclature,
  };
}
