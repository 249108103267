import AuthService from "@core/services/auth/authService";
import defaultConfig from "./cityDefault";
import { AxiosInstance } from "axios";
import {
  ICityUpdate,
  ICityService,
  TCityConfig,
  IResponseCity,
  IResponseListCounties,
  ICityUpdateResponse,
} from "@/@core/services/interfaces/covarege-area/city/ICityService";
import { IBodyListCounties } from "@/@core/services/interfaces/covarege-area/city/IBodySearch";

export default class CityService extends AuthService implements ICityService {
  serviceCityConfig: TCityConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceCityConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Retorna um município pelo ID
   */
  requestGet(cityId: string): Promise<IResponseCity> {
    return this.axiosIns.get(
      this.serviceCityConfig.getCityByIdEndpoint + "?id=" + cityId
    );
  }

  /**
   * Retorna uma lista de municípios
   */
  requestList(body: IBodyListCounties): Promise<IResponseListCounties> {
    const enablePagination = body.paginar ? "?paginar=true" : "";
    return this.axiosIns.post(
      this.serviceCityConfig.listCountiesEndpoint + enablePagination,
      body
    );
  }

  /**
   * Atualiza um município
   */
  requestUpdate(city: ICityUpdate): Promise<ICityUpdateResponse> {
    return this.axiosIns.put(this.serviceCityConfig.updateCityEndpoint, city);
  }
}
