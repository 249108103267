import { AxiosInstance } from "axios";
import ModelService from "./modelService";

export default function useModel(
  axiosIns: AxiosInstance,
  modelOverrideConfig: Object
) {
  const model = new ModelService(axiosIns, modelOverrideConfig);

  return {
    model,
  };
}
