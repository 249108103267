import { IUserState } from "./usersState";

export default {
  SET_EMAIL_RECOVER(state: IUserState, email: string): void {
    state.recoverPassword.email = email;
  },
  SET_CODE_RECOVER(state: IUserState, code: string): void {
    state.recoverPassword.code = code;
  },
  SET_RESET_RECOVER(state: IUserState): void {
    state.recoverPassword.email = "";
    state.recoverPassword.code = "";
  },
};
