import { Commit } from 'vuex/types/index';

export default {
  fetchPartners( { commit }: { commit: Commit } ) {
    return new Promise( ( resolve, reject ) => {
      const dataFake = {
        data: [
          {
            id    : 1,
            name  : "Partners P1",
            date  : "19/12/2022"
          },
          {
            id    : 2,
            name  : "Partners P2",
            date  : "19/12/2022"
          },
          {
            id    : 3,
            name  : "Partners P3",
            date  : "19/12/2022"
          }
        ],
        meta: {
          pagination: {
            total: 3
          }
        }
      };

      resolve( dataFake );
    } );
  }
}
