import { Commit } from 'vuex/types/index';

export default {
  fetchLibraries( { commit }: { commit: Commit } ) {
    return new Promise( ( resolve, reject ) => {
      const dataFake = {
        data: [
          {
            id    : 1,
            title : "Teste",
            number: "A1",
            type  : "Type",
            date  : "19/12/2022",
          },
          {
            id    : 2,
            title : "Teste A2",
            number: "A2",
            type  : "Type 2",
            date  : "19/12/2022",
          }
        ],
        meta: {
          pagination: {
            total: 2
          }
        }
      }

      resolve( dataFake );
    } );
  }
}