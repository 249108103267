import { Commit } from "vuex/types/index";
import { useOldService } from "@/services";
import {
  IResponseGetLargestEconomicGroupsYear,
  IResponseGetTotalMarketShareCurrentMonth,
  IResponseGetTotalMarketShareYearToDate,
  IResponseGetGrowthByBrand,
  IResponseGetDirectSaleVsRetailSaleBrand,
  IResponseGetDirectSaleVsRetailSaleYear,
  IResponseGetBrandEvolution,
  IResponseGetDailyBrandEvolution,
  IResponseUpdateDashboard,
} from "@core/services/interfaces/oldService/IOldService";

export default {
  fetchGetTotalMarketShareCurrentMonth({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseGetTotalMarketShareCurrentMonth> {
    return new Promise((resolve, reject) => {
      useOldService
        .requestGetTotalMarketShareCurrentMonth()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchGetTotalMarketShareYearToDate({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseGetTotalMarketShareYearToDate> {
    return new Promise((resolve, reject) => {
      useOldService
        .requestGetTotalMarketShareYearToDate()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchGetLargestEconomicGroupsYearDate({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseGetLargestEconomicGroupsYear> {
    return new Promise((resolve, reject) => {
      useOldService
        .requestGetLargestEconomicGroupsYear()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchGetGrowthByBrand({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseGetGrowthByBrand> {
    return new Promise((resolve, reject) => {
      useOldService
        .requestGetGrowthByBrand()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchDirectSaleVsRetailSaleBrand({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseGetDirectSaleVsRetailSaleBrand> {
    return new Promise((resolve, reject) => {
      useOldService
        .requestGetDirectSaleVsRetailSaleBrand()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchDirectSaleVsRetailSaleYear({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseGetDirectSaleVsRetailSaleYear> {
    return new Promise((resolve, reject) => {
      useOldService
        .requestGetDirectSaleVsRetailSaleYear()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchGetBrandEvolution({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseGetBrandEvolution> {
    return new Promise((resolve, reject) => {
      useOldService
        .requestGetBrandEvolution()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchGetDailyBrandEvolution({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseGetDailyBrandEvolution> {
    return new Promise((resolve, reject) => {
      useOldService
        .requestGetDailyBrandEvolution()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchUpdateDashboard({
    commit,
  }: {
    commit: Commit;
  }): Promise<IResponseUpdateDashboard> {
    return new Promise((resolve, reject) => {
      useOldService
        .requestUpdateDashboard()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
