
import { TSegmentConfig } from "@core/services/interfaces/vehicle/segment/ISegmentService";

const segmentConfig: TSegmentConfig = {
  // Endpoints
  getSegmentEndpoint: "/api/GerenciarVeiculos/Segmento/ObterSegmentoPorId",
  getSegmentTypesEndpoint: '/api/GerenciarVeiculos/Segmento/ObterTiposSegmento',
  listSegmentEndpoint: "/api/GerenciarVeiculos/Segmento/ObterSegmentos",
  createSegmentEndpoint: "/api/GerenciarVeiculos/Segmento/AdicionarSegmento",
  updateSegmentEndpoint: "/api/GerenciarVeiculos/Segmento/AtualizarSegmento",
  deleteSegmentEndpoint: "/api/GerenciarVeiculos/Segmento/RemoverSegmento",
}

export default segmentConfig;