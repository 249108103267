import { AxiosInstance } from "axios";
import CalendarService from "./calendarService";

export default function useParam(
  axiosIns: AxiosInstance,
  overrideConfig: Object
) {
  const calendar = new CalendarService(axiosIns, overrideConfig);

  return {
    calendar,
  };
}
