import { TGroupConfig } from "@core/services/interfaces/business/group/IGroupService";

const groupConfig: TGroupConfig  = {
  // Endpoints
  createGroupEndpoint   : '/api/GerenciarEmpresas/GrupoEmpresa/CriarGrupoEmpresa',
  updateGroupEndpoint   : '/api/GerenciarEmpresas/GrupoEmpresa/AtualizarGrupoEmpresa',
  deleteGroupEndpoint   : '/api/GerenciarEmpresas/GrupoEmpresa/RemoverGrupoEmpresa',
  listGroupEndpoint     : '/api/GerenciarEmpresas/GrupoEmpresa/ListarGruposEmpresa',
  getByIdGroupEndpoint  : '/api/GerenciarEmpresas/GrupoEmpresa',
}

export default groupConfig;