import actions from "./contactsActions";
import getters from "./contactsGetters";
import state from "./contactsState";
import mutations from "./contactsMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};