import { INomenclatureConfig } from "../../interfaces/vehicle/nomenclature/INomenclatureService";

const nomenclatureConfig: INomenclatureConfig = {
  // Endpoints
  listNomenclatureEndpoint:
    "/api/GerenciarVeiculos/Nomenclatura/ObterNomenclaturas",
  updateSingleModelEndpoint:
    "/api/GerenciarVeiculos/Nomenclatura/AtualizarUmaVersao",
  getCategoriesEndpoint: "/api/GerenciarVeiculos/CategoriaVeiculo",
};

export default nomenclatureConfig;
