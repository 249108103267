import actions from "./verticalMenuActions";
import getters from "./verticalMenuGetters";
import state from "./verticalMenuState";
import mutations from "./verticalMenuMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};