import useUser from '@core/services/user/useUser';
import axios from 'axios';
import defaultConfig from '../defaultConfig';

const axiosIns = axios.create( {
  baseURL: defaultConfig.userService,
} );

const { user } = useUser( axiosIns, {} );
export default user;
