import actions from "./areaActions";
import getters from "./areaGetters";
import state from "./areaState";
import mutations from "./areaMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};