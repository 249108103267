import { Commit } from "vuex/types/index";
import { mapEventsToAbilities } from "@/libs/acl/utils";
import {
  IUserDataCache,
  ISystem,
  IModule,
  IEvent,
} from "@core/services/interfaces/geralEmplacamento/cache/ICacheService";
import { IUserData } from "@core/services/interfaces/IUtil";

const mapUserEvents = (systems: ISystem[]): string[] => {
  let events: Array<string> = [];
  let eventHasBeenAdded: boolean = false;

  systems.forEach((system: ISystem) => {
    system.EventoRaiz.forEach((event: IEvent) => {
      events.push(event.EventoCodigo.toLocaleLowerCase());
    });

    system.Modulo.forEach((module: IModule) => {
      module.Evento.forEach((event: IEvent) => {
        eventHasBeenAdded = !!events.find(
          (e: string) => e == event.EventoCodigo
        );

        if (!eventHasBeenAdded)
          events.push(event.EventoCodigo.toLocaleLowerCase());
      });
    });
  });

  return events;
};

export default {
  fetchSetUserInfo(
    { commit }: { commit: Commit },
    options: { ctx: Vue; data: IUserDataCache }
  ) {
    const data = options.data;
    const ctx = options.ctx;
    const userEvents = mapUserEvents(data.Sistemas);
    const abilities = mapEventsToAbilities(userEvents);

    // Caso o usuário seja um administrador do sistema e estiver em ambiente dev, habilita todos os recursos do sistema
    if (data.EhAdm && process.env.VUE_APP_ENVIRONMENT === "develop") {
      abilities.push({ action: "manage", subject: "all" });
    }

    // Esse abilities serve para páginas que todos os usuários podem ver, como notificações
    abilities.push({ action: "read", subject: "Logged" });

    const userData: IUserData = {
      id: data.Id,
      groupId: data.GrupoId,
      fullName: data.Nome,
      username: data.Login,
      // eslint-disable-next-line global-require
      // avatar: require("@/assets/images/avatars/1-small.png"),
      email: data.Login,
      profileId: data.PerfilId,
      role: data.PerfilNome,
      ehAdmin: data.EhAdm,
      ability: abilities,
    };

    localStorage.setItem("userData", JSON.stringify(userData));
    ctx.$ability.update(userData.ability);

    return new Promise((resolve) => {
      resolve(userData);
    });
  },
};
