function placa(placa) {
  if (!placa) {
    return false;
  }

  let regexPlaca = /[A-Z|a-z]{3}[-]?[\d][a-z|A-Z0-9][\d]{2}$/;
  return regexPlaca.test(placa);
}

export default placa;
