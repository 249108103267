import axios from "axios";
import { Commit } from "vuex/types/index";

export default {
  fetchEvents( { commit }: { commit: Commit }, { calendars } : any ) : Promise<any> {
    return new Promise( ( resolve, reject ) => {
      axios
        .get( '/apps/calendar/events', {
          params: {
            calendars: calendars.join( ',' ),
          },
        } )
        .then( response => resolve( response ) )
        .catch( error => reject( error ) );
    } );
  },
  addEvent( { commit }: { commit: Commit }, { event } : any ) : Promise<any> {
    return new Promise( ( resolve, reject ) => {
      axios
        .post( '/apps/calendar/events', { event } )
        .then( response => resolve( response ) )
        .catch( error => reject( error ) );
    })
  },
  updateEvent( { commit }: { commit: Commit }, { event } : any ) : Promise<any> {
    return new Promise( ( resolve, reject ) => {
      axios
        .post( `/apps/calendar/events/${ event.id }`, { event } )
        .then( response => resolve( response ) )
        .catch( error => reject( error ) );
    })
  },
  removeEvent( { commit }: { commit: Commit }, { id } : any ) : Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete( `/apps/calendar/events/${ id }` )
        .then( response => resolve( response ) )
        .catch( error => reject( error ) );
    })
  },
};
