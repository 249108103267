import { Commit } from 'vuex/types/index';

export default {
  fetchPositions( { commit }: { commit: Commit } ) {
    return new Promise( ( resolve ) => {
      const dataFake = {
        data: [
          {
            id    : 1,
            title : "P1"
          },
          {
            id    : 2,
            title : "P2"
          },
          {
            id    : 3,
            title : "P3"
          },
          {
            id    : 4,
            title : "P4"
          },
          {
            id    : 5,
            title : "P5"
          },
        ],
        meta: {
          pagination: {
            total: 5
          }
        }
      }
      resolve( dataFake );
    } )
  },
}