import actions from "./appConfigActions";
import getters from "./appConfigGetters";
import state from "./appConfigState";
import mutations from "./appConfigMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};