import useBrandValidation from "@/@core/services/brand-validation/useBrandValidation";
import axios from "axios";
import defaultConfig from "../defaultConfig";

// defaultConfig.authenticationService
const axiosIns = axios.create({
  baseURL: defaultConfig.brandValidationService,
});

const { area } = useBrandValidation(axiosIns, {});
export default area;
