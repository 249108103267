import { Commit } from "vuex";
import useFirebase from "@/services/firebase/useFirebase";
import { IUserData } from "@core/services/interfaces/IUtil";
import {
  getFirestore,
  collection,
  onSnapshot,
  QuerySnapshot,
  DocumentData,
  doc,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { IFirebaseState, INotification } from "./firebaseState";

export default {
  startFirestoreRealtimeTracking({ commit }: { commit: Commit }) {
    const currentUser: IUserData = JSON.parse(
      localStorage.getItem("userData") || "null"
    );

    if (currentUser) {
      const db = getFirestore(useFirebase);
      const notificationCollection = collection(
        db,
        "notificacoes_emplacamento",
        currentUser.id,
        "notificacoes_usuario"
      );
      const userProcessingCollection = collection(db, "processamento_usuarios");

      const onNotificationCallback = (
        snapshot: QuerySnapshot<DocumentData, DocumentData>
      ) => {
        const data = snapshot.docs.map((it) => {
          const d = it.data();
          return {
            Id: it.id,
            Downloading: false,
            ...d,
          };
        });

        commit("SET_NOTIFICATIONS", data);
      };

      const onUserProcessingCallback = (
        snapshot: QuerySnapshot<DocumentData, DocumentData>
      ) => {
        const data = snapshot.docs.map((it) => it.id);

        commit("SET_USER_PROCESSING", data);
      };

      // Subscribe em notificações
      const unsubscribeNotification = onSnapshot(
        notificationCollection,
        onNotificationCallback
      );
      const unsubscribeUserProcessing = onSnapshot(
        userProcessingCollection,
        onUserProcessingCallback
      );

      commit("SET_UNSUBSCRIBE", [
        unsubscribeNotification,
        unsubscribeUserProcessing,
      ]);
    }
  },
  stopFirestoreRealtimeTracking({
    commit,
    state,
  }: {
    commit: Commit;
    state: IFirebaseState;
  }) {
    if (state.unsubscribe.length > 0) {
      state.unsubscribe.forEach((usub) => {
        usub();
      });
      commit("SET_UNSUBSCRIBE", []);
    }
  },
  startFirestoreRealTimeTrackingValidateBrand({ commit }: { commit: Commit }) {
    const db = getFirestore(useFirebase);
    const brandValidationsCollection = collection(db, "log_validacao_marca");

    const onCallback = (
      snapshot: QuerySnapshot<DocumentData, DocumentData>
    ) => {
      const data = snapshot.docs.map((it) => {
        const d = it.data();
        return {
          Id: it.id,
          ...d,
        };
      });

      commit("SET_BRAND_VALIDATIONS", data);
    };

    // Subscribe em notificações
    const unsubscribe = onSnapshot(brandValidationsCollection, onCallback);

    commit("SET_UNSUBSCRIBE_BRAND_VALIDATIONS", [unsubscribe]);
  },
  stopFirestoreRealTimeTrackingValidateBrand({
    commit,
    state,
  }: {
    commit: Commit;
    state: IFirebaseState;
  }) {
    if (state.unsubscribeBrandValidations.length > 0) {
      state.unsubscribeBrandValidations.forEach((usub) => {
        usub();
      });
      commit("SET_UNSUBSCRIBE_BRAND_VALIDATIONS", []);
    }
  },
  startFirestoreRealTimeTrackingBillings({ commit }: { commit: Commit }) {
    const db = getFirestore(useFirebase);
    const billingCollection = collection(db, "log_faturamento");

    const onCallback = (
      snapshot: QuerySnapshot<DocumentData, DocumentData>
    ) => {
      const data = snapshot.docs.map((it) => {
        const d = it.data();
        return {
          Id: it.id,
          ...d,
        };
      });

      commit("SET_BILLINGS", data);
    };

    // Subscribe em notificações
    const unsubscribe = onSnapshot(billingCollection, onCallback);

    commit("SET_UNSUBSCRIBE_BILLINGS", [unsubscribe]);
  },
  stopFirestoreRealTimeTrackingBillings({
    commit,
    state,
  }: {
    commit: Commit;
    state: IFirebaseState;
  }) {
    if (state.unsubscribeBillings.length > 0) {
      state.unsubscribeBillings.forEach((usub) => {
        usub();
      });
      commit("SET_UNSUBSCRIBE_BILLINGS", []);
    }
  },
  async updateNotification(
    { commit }: { commit: Commit },
    notification: INotification
  ) {
    const currentUser: IUserData = JSON.parse(
      localStorage.getItem("userData") || "null"
    );

    if (currentUser) {
      const db = getFirestore(useFirebase);
      const notificationDoc = doc(
        db,
        "notificacoes_emplacamento",
        currentUser.id,
        "notificacoes_usuario",
        notification.Id
      );

      await updateDoc(notificationDoc, { ...notification });
    }
  },
  async deleteNotification(
    { commit }: { commit: Commit },
    notificationId: string
  ) {
    const currentUser: IUserData = JSON.parse(
      localStorage.getItem("userData") || "null"
    );

    if (currentUser) {
      const db = getFirestore(useFirebase);
      const notificationDoc = doc(
        db,
        "notificacoes_emplacamento",
        currentUser.id,
        "notificacoes_usuario",
        notificationId
      );

      await deleteDoc(notificationDoc);
    }
  },
  async deleteDoc(
    { commit }: { commit: Commit },
    payload: {
      path: string;
      pathSegments: string[];
    }
  ) {
    const currentUser: IUserData = JSON.parse(
      localStorage.getItem("userData") || "null"
    );

    if (currentUser) {
      const db = getFirestore(useFirebase);
      const docRef = doc(db, payload.path, ...payload.pathSegments);

      await deleteDoc(docRef);
    }
  },
  async addUserProcessing({ commit }: { commit: Commit }, userId: string) {
    const db = getFirestore(useFirebase);
    setDoc(doc(db, "processamento_usuarios", userId), {});
  },
};
