import AuthService from "@core/services/auth/authService";
import defaultConfig from "./areaDefault";
import { AxiosInstance } from "axios";
import {
  IAreaService,
  TAreaConfig,
  IResponseArea,
  IResponseListArea,
  IBodyAddArea,
  IResponseAddArea,
  IBodyEditArea,
  IResponseEditArea,
  IBodyListInfluencedCitiesArea,
  IResponseListInfluencedCitiesArea,
  IResponseDeleteArea,
  IResponseListInfluenceScopes,
} from "@core/services/interfaces/covarege-area/area/IAreaService";
import { IBodyListArea } from "@core/services/interfaces/covarege-area/area/IBodySearch";

export default class AreaService extends AuthService implements IAreaService {
  serviceAreaConfig: TAreaConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceAreaConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Retorna uma area operacional/influencia pelo ID
   */
  requestGet(id: string): Promise<IResponseArea> {
    return this.axiosIns.get(
      this.serviceAreaConfig.getAreaByIdEndpoint + "?areaInfluenciaId=" + id
    );
  }

  /**
   * Retorna uma lista de area operacional/influencia
   */
  requestList(body: IBodyListArea): Promise<IResponseListArea> {
    const enablePagination = body.paginate ? `?paginar=${body.paginate}` : "";
    return this.axiosIns.post(
      this.serviceAreaConfig.listAreaEndpoint + enablePagination,
      body
    );
  }

  /**
   * Adicionando uma área operacional
   */
  requestAdd(body: IBodyAddArea): Promise<IResponseAddArea> {
    return this.axiosIns.post(this.serviceAreaConfig.addAreaEndpoint, body);
  }

  /**
   * Editar uma área operacional
   */
  requestEdit(body: IBodyEditArea): Promise<IResponseEditArea> {
    return this.axiosIns.put(this.serviceAreaConfig.editAreaEndpoint, body);
  }

  requestListInfluencedCities(
    body: IBodyListInfluencedCitiesArea
  ): Promise<IResponseListInfluencedCitiesArea> {
    return this.axiosIns.get(
      this.serviceAreaConfig.listInfluencedCitiesAreaEndpoint +
        `?id=${body.id}&influencia=${body.influencia.toString()}`
    );
  }

  requestListInfluenceScopes(): Promise<IResponseListInfluenceScopes> {
    return this.axiosIns.get(
      this.serviceAreaConfig.listInfluenceAreasScopeEndpoint
    );
  }

  requestDelete(id: number): Promise<IResponseDeleteArea> {
    return this.axiosIns.delete(
      this.serviceAreaConfig.deleteAreaEndpoint + `?areaInfluenciaId=${id}`
    );
  }
}
