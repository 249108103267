import { TUserConfig } from "@core/services/interfaces/user/IUserService";

const userConfig: TUserConfig = {
  // Endpoints
  getByIdUserEndpoint: "/api/GerenciarUsuarios/Usuario/ObterUsuariosPorIds",
  getListUsersEndpoint: "/api/GerenciarUsuarios/Usuario/ListarUsuarios",
  createUsersEndpoint:
    "/api/GerenciarUsuarios/Usuario/CriarUsuarioEmplacamento",
  getLoggedUserEndpoint: "/api/GerenciarUsuarios/Usuario/ObterUsuarioLogado",
  updateUserEndpoint:
    "/api/GerenciarUsuarios/Usuario/AtualizarUsuarioEmplacamento",
  getDailyReportTypesEndpoint:
    "/api/GerenciarUsuarios/Usuario/ObterTiposBoletim",
};

export default userConfig;
