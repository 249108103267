import { Commit } from "vuex/types/index";
import { useModel } from "@/services";
import {
  IModelCreate,
  IModelCreateResponse,
  IModelUpdate,
  IModelUpdateResponse,
} from "@/@core/services/interfaces/vehicle/models/IModelService";

export default {
  fetchCreateModel(
    { commit }: { commit: Commit },
    model: IModelCreate
  ): Promise<IModelCreateResponse> {
    return new Promise((resolve, reject) => {
      useModel
        .requestCreate(model)
        .then((response: IModelCreateResponse) => {
          resolve(response);
        })
        .catch((error: IModelCreateResponse) => {
          reject(error);
        });
    });
  },

  fetchUpdateModel(
    { commit }: { commit: Commit },
    model: IModelUpdate
  ): Promise<IModelUpdateResponse> {
    return new Promise((resolve, reject) => {
      useModel
        .requestUpdate(model)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
};
