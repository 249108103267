import actions from "./appActions";
import getters from "./appGetters";
import state from "./appState";
import mutations from "./appMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};