import AuthService from "@core/services/auth/authService";
import defaultConfig from "./modelDefaultConfig";
import { AxiosInstance } from "axios";
import {
  IModel,
  IModelCreate,
  IModelCreateResponse,
  IModelService,
  IModelUpdate,
  IModelUpdateResponse,
  IResponseGetModelById,
  IResponseListModels,
  TModelConfig,
} from "../../interfaces/vehicle/models/IModelService";
import { IBodyListModels } from "../../interfaces/vehicle/models/IModelService";

export default class ModelService extends AuthService implements IModelService {
  modelServiceConfig: TModelConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.modelServiceConfig = { ...defaultConfig, ...overrideConfig };
  }

  requestGetById(modelId: number): Promise<IResponseGetModelById> {
    return this.axiosIns.get(
      this.modelServiceConfig.getModelByIdEndpoint + "?id=" + modelId
    );
  }

  requestList(args: IBodyListModels): Promise<IResponseListModels> {
    let strArgs = `?${args.paginate ? "paginar=true" : "paginar=false"}`;
    return this.axiosIns.post(
      this.modelServiceConfig.listModelsEndpoint + strArgs,
      args
    );
  }

  requestUpdate(model: IModelUpdate): Promise<IModelUpdateResponse> {
    return this.axiosIns.put(
      this.modelServiceConfig.updateModelEndpoint,
      model
    );
  }

  requestCreate(model: IModelCreate): Promise<IModelCreateResponse> {
    return this.axiosIns.post(
      this.modelServiceConfig.createModelEndpoint,
      model
    );
  }
}
