import { IParameters } from "@core/services/interfaces/oldService/IOldService";
import { IMonth, IEffectivateBillingState } from "./effectivateBillingState";

export default {
  /**
   * Recebe uma lista de meses e set no state months
   */
  SET_MONTHS(state: IEffectivateBillingState, months: IMonth[]): void {
    state.months = months;
  },
};
