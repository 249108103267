import { $themeBreakpoints } from '@themeConfig';
import { IAppState } from './appState';

export default {
  currentBreakPoint: ( state: IAppState ) : string => {
    const { windowWidth } = state;
    if( windowWidth >= $themeBreakpoints.xl ) return 'xl'
    if( windowWidth >= $themeBreakpoints.lg ) return 'lg'
    if( windowWidth >= $themeBreakpoints.md ) return 'md'
    if( windowWidth >= $themeBreakpoints.sm ) return 'sm'
    return 'xs';
  }
};