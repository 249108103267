function validarCpf(cpf) {
  // Lista de valores incorretos comuns.
  const ValoresIncorretosComun = [
    "00000000000",
    "11111111111",
    "22222222222",
    "33333333333",
    "44444444444",
    "55555555555",
    "66666666666",
    "77777777777",
    "88888888888",
    "99999999999",
    "12345678909",
  ];

  const regexRemoverPontos = /[.-]/g;
  const regexApenasNumeros = /[^\d]/g;

  const verificarDigitos = (digitos) => {
    const numeros = digitos.split("").map((numero) => {
      return parseInt(numero, 10);
    });

    const modulos = numeros.length + 1;
    const multiplicado = numeros.map(
      (numero, index) => numero * (modulos - index)
    );
    const mod = multiplicado.reduce((buffer, numero) => buffer + numero) % 11;

    return mod < 2 ? 0 : 11 - mod;
  };

  const strip = (numero, strict) => {
    const regex = strict ? regexRemoverPontos : regexApenasNumeros;
    return (numero || "").replace(regex, "");
  };

  const isValid = (numero, strict) => {
    const stripped = strip(numero, strict);

    // CPF deve ser definido
    if (!stripped) {
      return false;
    }

    // CPF deve ter 11 caracteres
    if (stripped.length !== 11) {
      return false;
    }

    // CPF não pode ser valores incorretos comumente utilizados
    if (ValoresIncorretosComun.includes(stripped)) {
      return false;
    }

    let numeros = stripped.substr(0, 9);
    numeros += verificarDigitos(numeros);
    numeros += verificarDigitos(numeros);

    return numeros.substr(-2) === stripped.substr(-2);
  };

  return isValid(cpf);
}

function validarCnpj(cnpj) {
  // Lista de valores incorretos comuns.
  const ValoresIncorretosComun = [
    "00000000000000",
    "11111111111111",
    "22222222222222",
    "33333333333333",
    "44444444444444",
    "55555555555555",
    "66666666666666",
    "77777777777777",
    "88888888888888",
    "99999999999999",
  ];

  const regexRemoverPontos = /[-\\/.]/g;
  const regexApenasNumeros = /[^\d]/g;

  const verificarDigitos = (digitos) => {
    let index = 2;
    const reverse = digitos.split("").reduce((buffer, number) => {
      return [parseInt(number, 10)].concat(buffer);
    }, []);

    const sum = reverse.reduce((buffer, number) => {
      buffer += number * index;
      index = index === 9 ? 2 : index + 1;
      return buffer;
    }, 0);

    const mod = sum % 11;
    return mod < 2 ? 0 : 11 - mod;
  };

  const strip = (number, strict) => {
    const regex = strict ? regexRemoverPontos : regexApenasNumeros;
    return (number || "").replace(regex, "");
  };

  const isValid = (number, strict) => {
    const stripped = strip(number, strict);

    // CNPJ deve ser definido
    if (!stripped) {
      return false;
    }

    // CNPJ deve ter 11 caracteres
    if (stripped.length !== 14) {
      return false;
    }

    // CNPJ não pode ser valores incorretos comumente utilizados
    if (ValoresIncorretosComun.includes(stripped)) {
      return false;
    }

    let numbers = stripped.substr(0, 12);
    numbers += verificarDigitos(numbers);
    numbers += verificarDigitos(numbers);

    return numbers.substr(-2) === stripped.substr(-2);
  };

  return isValid(cnpj);
}

function cpfOuCnpj(cpfOuCnpj) {
  if (cpfOuCnpj.length > 14) {
    return validarCnpj(cpfOuCnpj);
  } else {
    return validarCpf(cpfOuCnpj);
  }
}

export { cpfOuCnpj, validarCnpj, validarCpf };
