import axios from "axios";
import defaultConfig from "../defaultConfig";
import useOldService from "@core/services/oldService/useOldService";

const axiosIns = axios.create({
  baseURL: defaultConfig.oldService,
  headers: {
    "Content-Type": "application/json",
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  },
});

const { oldService } = useOldService(axiosIns, {});
export default oldService;
