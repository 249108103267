function renavamIsValid(renavam) {
  const renavamArray = renavam.split("");
  let somaRenavam = 0;
  renavamArray.forEach((item) => {
    somaRenavam += parseInt(item);
  });

  if (somaRenavam === 0) return false;
  if (!renavam) return true;
  renavam = renavam.toString();
  if (renavam.length < 11) return false;

  let renavamDigits = renavam.trim();
  let renavamSemDigito = renavamDigits.substring(0, 10);
  let renavamReversoSemDigito = renavamSemDigito.split("").reverse().join("");
  let sum = 0;
  let multiplicador = 2;
  for (let i = 0; i < 10; i++) {
    let algarismo = parseInt(renavamReversoSemDigito.substring(i, i + 1));
    sum += algarismo * multiplicador;

    if (multiplicador >= 9) {
      multiplicador = 2;
    } else {
      multiplicador++;
    }
  }

  let modulo = sum % 11;
  let ultimoDigitoCalculado = 11 - modulo;

  ultimoDigitoCalculado = ultimoDigitoCalculado >= 10 ? 0 : ultimoDigitoCalculado;

  let digitoRealInformado = parseInt(renavam.substring(renavam.length - 1, renavam.length));

  if (ultimoDigitoCalculado == digitoRealInformado) return true;
  return false;
}

export default renavamIsValid;
