import AuthService from "@core/services/auth/authService";
import defaultConfig from "./paramDefaultConfig";
import { AxiosInstance } from "axios";
import {
  IParamService,
  IParamConfig,
  IResponseGetParameterValue,
} from "@core/services/interfaces/geral/param/IParamService";

export default class ParamService extends AuthService implements IParamService {
  serviceParamConfig: IParamConfig;

  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceParamConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Consulta um parametro de sistema pelo seu name
   */
  requestGetParameterValue(
    paramId: string
  ): Promise<IResponseGetParameterValue> {
    return this.axiosIns.get(
      this.serviceParamConfig.getParameterValueEndpoint +
        `?parametroId=${paramId}`,
      {
        headers: {
          domain: this.getDomain(),
        },
      }
    );
  }
}
