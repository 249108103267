import Vue from "vue";
import Vuex from "vuex";

// Modules
import authentication from "./authentication";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import appContact from "./admin-panel/contacts";
import adminPanelCounty from "./admin-panel/counties";
import appDistrict from "./admin-panel/districts";
import events from "./admin-panel/events";
import appLibrary from "./admin-panel/libraries";
import adminMagazines from "./admin-panel/magazines";
import adminPartners from "./admin-panel/partners";
import adminPositions from "./admin-panel/positions";
import appRegion from "./admin-panel/regions";
import adminPanelSlider from "./admin-panel/sliders";
import appUsers from "./admin-panel/users";
import adminGroupEconomic from "./admin-panel/groups-economic";
import adminSegments from "./admin-panel/segments";
import adminModel from "./admin-panel/models";
import brands from "./admin-panel/brands";
import region from "./admin-panel/region";
import admUsers from "./administrative/users";
import city from "./admin-panel/city";
import area from "./admin-panel/area";
import state from "./admin-panel/state";
import oldService from "./old-service/index";
import effectivateBilling from "./admin-panel/effectivate-billing";
import firebase from "./firebase";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication,
    app,
    appConfig,
    verticalMenu,
    "app-contact": appContact,
    "admin-panel-county": adminPanelCounty,
    "app-district": appDistrict,
    calendar: events,
    "app-library": appLibrary,
    "admin-magazines": adminMagazines,
    "admin-partners": adminPartners,
    "admin-positions": adminPositions,
    "app-region": appRegion,
    "admin-panel-slider": adminPanelSlider,
    "app-user": appUsers,
    "admin-group-economic": adminGroupEconomic,
    "admin-city": city,
    "admin-state": state,
    "admin-brands": brands,
    "admin-model": adminModel,
    "admin-region": region,
    "admin-area": area,
    "admin-nomenclature-segments": adminSegments,
    "admin-users": admUsers,
    "old-service": oldService,
    "effectivate-billing": effectivateBilling,
    firebase,
  },
  strict: process.env.VUE_APP_ENVIRONMENT !== "production",
});
