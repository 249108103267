import actions from "./slidersActions";
import getters from "./slidersGetters";
import state from "./slidersState";
import mutations from "./slidersMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};