import { TEndPointConfig } from "@/services/interfaces/IConfigServices";

const defaultConfig: TEndPointConfig = {
  // Services
  oldService:
    process.env.VUE_APP_OLD_SERVICE || "http://emplacamento.dev.abrare.com.br",
  generalService:
    process.env.VUE_APP_GENERAL_SERVICE ||
    "https://3dp3druh05.execute-api.us-east-1.amazonaws.com/Prod",
  generalEmplacamentoService:
    process.env.VUE_APP_GENERAL_EMPLACAMENTO_SERVICE ||
    "https://rgh9vs2boc.execute-api.us-east-1.amazonaws.com/Prod",
  authenticationService:
    process.env.VUE_APP_AUTHENTICATION_SERVICE ||
    "https://9ky8dbc159.execute-api.us-east-1.amazonaws.com/Prod",
  administrationService:
    process.env.VUE_APP_ADMINISTRATION_SERVICE ||
    "https://oqv2aq2b00.execute-api.us-east-1.amazonaws.com/Prod",
  businessService:
    process.env.VUE_APP_BUSINESS_SERVICE ||
    "https://ltw1ov8e3i.execute-api.us-east-1.amazonaws.com/Prod",
  userService:
    process.env.VUE_APP_USER_SERVICE ||
    "https://dmfsaux2oe.execute-api.us-east-1.amazonaws.com/Prod",
  coverageAreaService:
    process.env.VUE_APP_COVERAGE_AREA_SERVICE ||
    "https://vw13maujgl.execute-api.us-east-1.amazonaws.com/Prod",
  vehicleService:
    process.env.VUE_APP_VEHICLE_SERVICE ||
    "https://5u0vk17c99.execute-api.us-east-1.amazonaws.com/Prod",
  brandValidationService:
    process.env.VUE_APP_BRAND_VALIDATION_SERVICE ||
    "https://eigqpjww9d.execute-api.us-east-1.amazonaws.com/Prod",
};

export default defaultConfig;
