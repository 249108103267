import { IArea, IAreaState } from "./areaState";

export default {
  /**
   * Recebe uma lista de areao operacional/influencia e set no state Area
   */
  SET_COUNTY(
    state: IAreaState,
    area: IArea[]
  ) : void {
    state.area = area;
  }
};