import useModel from "@/@core/services/vehicle/models/useModel";
import axios from "axios";
import defaultConfig from "../../defaultConfig";

// defaultConfig.authenticationService
const axiosIns = axios.create({
  baseURL: defaultConfig.vehicleService,
});

const { model } = useModel(axiosIns, {});
export default model;
