export interface IContactsState {}

let contactsState: IContactsState = {};

export default contactsState;

export type TResponseContacts = {
  data: Array<{
    id        : number,
    name      : string,
    position  : string,
    dpo       : boolean,
    email     : string,
    telephone : string,
  }>,
  meta: {
    pagination: {
      total: number
    }
  }
}
