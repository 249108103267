export interface IStateState {
  counties: IStates[];
  states: IStates[]
}

let stateState: IStateState = {
  counties: [],
  states: []
};

export default stateState;

export interface IStates {
  Id: number,
  nome: string,
  codigoSerpro: string,
  regiaoOperacionalID: number,
  regiaoOperacional: Array<IRegionCity>,
  codigoIbge: string,
  areaInfluenciaID: number,
  areaInfluencia: Array<IAreaCity>
}

export interface IRegionCity {
  id? : number,
  nome: string,
  status: boolean,
  author: string
}

export interface IAreaCity {
  id? : number,
  nome: string,
  regiaoOperacionalId: number,
  municipioSedeId: number | null,
  codigo: number,
  estadoId: number,
}

export interface IRegiaoGeo {
  Id?: number,
  Nome?: string,
  codigo: string,
}

export interface IState {
  Id: number,
  nome: string,
  codigoSerpro: string,
  regiaoOperacionalID: number,
  regiaoOperacional: Array<IRegionCity>,
  codigoIbge: string,
  areaInfluenciaID: number,
  areaInfluencia: Array<IAreaCity>
}