export interface IDistrictsState {}

let districtsState: IDistrictsState = {};

export default districtsState;

export type TResponseDistricts = {
  data: Array<{
    Id    : number,
    name  : string,
    code  : string,
    status: boolean,
  }>,
  draw            : number,
  recordsTotal    : number,
  recordsFiltered : number,
  lastPage        : number
}