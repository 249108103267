import actions from "./countiesActions";
import getters from "./countiesGetters";
import state from "./countiesState";
import mutations from "./countiesMutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};